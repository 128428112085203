import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import moment from "moment";
import API from "../../../utils/api";
import Papa from "papaparse";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  mobile: "",
  firstname: "",
  lastname: "",
  email: "",
  birthday: moment().format("YYYY-MM-DD"),
  tenants: [],
  spam: false,
  labels: [],
}

const FormDialogAddCustomer = (props) => {
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [bulk, setBulk] = useState('single');
  const [loader, setloader] = useState(false);

  const handleClickOpen = () => {
    setErrors({});
    setCustomer(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setCustomer({ ...customer, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;
    if (!customer.mobile || customer.mobile.trim() === "") {
      formIsValid = false;
      tempErrors["mobile"] = "Cannot be empty";
    }
    if (!customer.firstname || customer.firstname.trim() === "") {
      formIsValid = false;
      tempErrors["firstname"] = "Cannot be empty";
    }
    if (!customer.lastname || customer.lastname.trim() === "") {
      formIsValid = false;
      tempErrors["lastname"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
      
    }
    e.preventDefault();

    if (validate()) {
      props.create(customer, onSuccess)
    }
  }

  const handleInputChangeForFileType = event => {
    setloader(true)

    const files = event.target.files;
         // console.log(files);
          const onSuccess = (res) => {
            console.log('bulkup',res);
            if(typeof res?.result?.nInserted !== "undefined"){
              if(res?.result?.nInserted !==0){
                props.refresh()
                toast.success('contacts uploaded: '+res?.result?.nInserted+'  duplicates skipped: '+ res?.writeErrors?.length);
              }else{
                toast.error('Failed. all the rows have duplicate mobile numbers.');
              }
            }else{
              toast.success('contacts uploaded.');

            }
           
            props.refresh()
            setloader(false)
          }
          if (files) {
            //console.log(files[0]);
            Papa.parse(files[0], {
              header: true,
              complete: function(results) {
                console.log("Finished:", results.data);
                let csvobj = {}
                csvobj.customers = results.data
                csvobj.labels = customer.labels
                props.fetchBulkContacts(csvobj,onSuccess)

              }}
            )
          }else{
            setloader(false)

          }

  }
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add Customer</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="bulk" name="bulk" value={bulk} onChange={e => setBulk(e.target.value)}>
              <FormControlLabel value="single" control={<Radio />} label="One by one" />
              <FormControlLabel value="bulk" control={<Radio />} label="Bulk Upload" />
            </RadioGroup>
          </FormControl>
        
          {bulk === 'bulk' ?
            <>
              <div className="col-lg-12 col-12 mt-2 mb-5">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Labels</InputLabel>
                <Select
                  name="labels"
                  label="Labels"
                  value={customer.labels}
                  onChange={handleInputChange}
                  multiple
                >
                  {props.labels.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
           {customer.labels.length>0 &&
              <label for="images" class="drop-container" id="dropcontainer">
                <span class="drop-title">Drop CSV file here</span>
                or
                <input type="file"
                  name="file"
                  label="Choose CSV file"
                  accept=".csv,.jpg,.gif"
                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })} id="images" />
              </label>
            
              }

              <div className="small mt-3">Maximum rows allowed 500. <a className="text-success" href="https://www.whatsapp.alzaabigroup.com/api/uploads/sample_customer.csv" target="_blank">Donwload Sample File</a></div>

              {loader &&
          <>
          <div class="spinner-border text-success spinner-border-sm me-3 mt-3" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> Please wait, upload is processing...
          </>}
              {/* <Button variant="contained" component="label" size="large">
                Upload CSV <sub>&nbsp; 1mb max</sub>
                <input
                  type="file"
                  name="file"
                  label="Choose CSV file"
                  accept=".csv,.jpg,.gif"
                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button> */}
            </>
            :
            <>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <TextField

                    name="mobile"
                    label="Mobile"
                    value={customer.mobile}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.mobile && { error: true, helperText: errors.mobile })}
                    style={{ marginBottom: 25 }}
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <TextField
                    autoFocus
                    name="firstname"
                    label="First Name"
                    value={customer.firstname}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.firstname && { error: true, helperText: errors.firstname })}
                    required
                    style={{ marginBottom: 15 }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-6 col-12">
                  <TextField

                    name="lastname"
                    label="Last Name"
                    value={customer.lastname}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.lastname && { error: true, helperText: errors.lastname })}
                    required
                    style={{ marginBottom: 15 }}
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <TextField
                    name="email"
                    label="Email"
                    value={customer.email}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.email && { error: true, helperText: errors.email })}
                    required
                    style={{ marginBottom: 15 }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-12 mb-3">
                  <TextField
                    type="date"
                    name="birthday"
                    label="DOB"
                    value={moment(customer.birthday).format("YYYY-MM-DD")}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.birthday && { error: true, helperText: errors.birthday })}
                    required
                    style={{ marginBottom: 15 }}
                  />
                </div>
                <div className="col-lg-12 col-12 mb-3">
                  <FormControl
                    fullWidth={true}
                  >
                    <InputLabel id="demo-simple-select-label">Spam</InputLabel>
                    <Select
                      name="spam"
                      label="Spam"
                      value={customer.spam}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* <div className="col-lg-12 col-12">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Organizations</InputLabel>
                <Select
                  name="tenants"
                  label="tenants"
                  value={customer.tenants}
                  onChange={handleInputChange}
                  multiple
                >
                  {props.tenants.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div> */}
              </div>
              
            </>}
        </DialogContent>


        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Close
          </Button>
          {bulk==='single' && <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>}
        
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddCustomer;