import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "uploads/customer-service.png",
  firstname: "",
  lastname: "",
  email: "",
  mobile: "",
  displayname: "",
  gender: "",
  role: "agent",
  password: "",
  tenants: []
}

const FormDialogEditUser = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setUser({
      id: props.dataUser[0],
      image: props.dataUser[1],
      firstname: props.dataUser[2],
      lastname: props.dataUser[3],
      email: props.dataUser[4],
      mobile: props.dataUser[5],
      displayname: props.dataUser[6],
      gender: props.dataUser[7],
      role: props.dataUser[8],
      password: props.dataUser[9],
      tenants: props.dataUser[10]?props.dataUser[10]:[],
    })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setUser({ ...user, [name]: value })
  }

  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 150) {
      toast.warning('maximum size allowed: 150kb');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setUser({ ...user, image: res.data.data.path })
      })

  }


  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;
    if (!user.firstname || user.firstname.trim() === "") {
      formIsValid = false;
      tempErrors["firstname"] = "Cannot be empty";
    }
    if (!user.lastname || user.lastname.trim() === "") {
      formIsValid = false;
      tempErrors["lastname"] = "Cannot be empty";
    }

    if (!user.email || user.email.trim() === "") {
      formIsValid = false;
      tempErrors["email"] = "Cannot be empty";
    }
    if (!user.displayname || user.displayname.trim() === "") {
      formIsValid = false;
      tempErrors["displayname"] = "Cannot be empty";
    }
    if (!user.password || user.password.trim() === "") {
      formIsValid = false;
      tempErrors["password"] = "Cannot be empty";
    }

    let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexp.test(user.email)) {
      formIsValid = false;
      tempErrors["email"] = "Email is not valid";
    }

    if (!user.password || user.password.trim() === "") {
      formIsValid = false;
      tempErrors["password"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.update(user.id, user, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <div className="row">
            <div className="col-lg-12 col-12 mb-3">
              <Button variant="contained" component="label">
                Upload Avatar <sub>&nbsp; 150kb max</sub>
                <input
                  type="file"
                  name="file"
                  label="Image"
                  accept=".png,.jpg,.gif"
                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button>
              {user.image.length > 0 && <img src={process.env.REACT_APP_IMG_URL + user.image} height="100" style={{ marginTop: "-30px", float: "right" }} />}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <TextField

                name="displayname"
                label="Display Name"
                value={user.displayname}
                fullWidth
                onChange={handleInputChange}
                {...(errors.displayname && { error: true, helperText: errors.displayname })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField
                autoFocus
                name="firstname"
                label="First Name"
                value={user.firstname}
                fullWidth
                onChange={handleInputChange}
                {...(errors.firstname && { error: true, helperText: errors.firstname })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <TextField

                name="lastname"
                label="Last Name"
                value={user.lastname}
                fullWidth
                onChange={handleInputChange}
                {...(errors.lastname && { error: true, helperText: errors.lastname })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField
                name="email"
                label="Email"
                value={user.email}
                fullWidth
                onChange={handleInputChange}
                {...(errors.email && { error: true, helperText: errors.email })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <TextField
                type="password"
                name="password"
                label="Password"
                value={user.password}
                fullWidth
                onChange={handleInputChange}
                {...(errors.password && { error: true, helperText: errors.password })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField

                name="mobile"
                label="Mobile"
                value={user.mobile}
                fullWidth
                onChange={handleInputChange}
                {...(errors.mobile && { error: true, helperText: errors.mobile })}
                style={{ marginBottom: 25 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12 mb-3">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  name="role"
                  label="role"
                  value={user.role}
                  onChange={handleInputChange}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="agent">Agent</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-12">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Organizations</InputLabel>
                <Select
                  name="tenants"
                  label="tenants"
                  value={user.tenants}
                  onChange={handleInputChange}
                  multiple
                >
                  {props.tenants.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">

            </div>
            <div className="col-lg-6 col-12">

            </div>
          </div>














          {/* <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user.gender}
              onChange={handleInputChange}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="fema">Female</MenuItem>
            </Select>
          </FormControl> */}



        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditUser;