import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button, Select, InputLabel, FormControl
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  MenuOpenOutlined as MenuOpenOutlinedIcon,
  NotificationImportant as NotificationImportant,
  NotificationsActive as NotificationsActive
} from "@material-ui/icons";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers/Wrappers";
import {isMobile} from 'react-device-detect';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { AuthContext } from "../../context/AuthContext";
import * as tenants from "./../../actions/tenant";
import * as tenantnumbers from "./../../actions/tenantnumber";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { socket } from "../../context/socket";
import Notification from "react-web-notification";
import { store } from "./../../store/store";

function Header(props) {
  // global
  const classes = useStyles();
  const [business, setBusiness] = useState("")
  const [phonenumber, setPhonenumber] = useState("")
  const [newmsgarr, setNewmsgarr] = useState([])
  const phonenumberRef = React.useRef(phonenumber);

  const { logout, getAuthUser } = useContext(AuthContext)

  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const authUser = getAuthUser()

  // local
  var [profileMenu, setProfileMenu] = useState(null);


  useEffect(() => {

    props.fetchAllTenantnumbers()
    let currentTenantnumber = Cookies?.get('currentTenantnumber')
    if (currentTenantnumber !== "undefined" && typeof currentTenantnumber !== "undefined") {
      currentTenantnumber = JSON?.parse(currentTenantnumber)
      setPhonenumber(currentTenantnumber?.phone_number)
      phonenumberRef.current = currentTenantnumber?.phone_number;

     // console.log('0000',currentTenantnumber)
      props.fetchcurrentTenantnumber(currentTenantnumber)

    }
 
    const msgupdateEvent = (value) => {

      let oldstore = store.getState()
      let allowedphonenumbers = oldstore.tenantnumber.tenantnumbers.filter(t =>authUser.role!=='superadmin'?authUser?.tenants?.includes(t.tenant.id):t).map(a => a.phone_number)
        // console.log('notification-->', value, phonenumberRef.current, oldstore.tenantnumber.tenantnumbers.filter(t =>authUser?.tenants?.includes(t.tenant.id)).map(a => a.phone_number))
     
        if(!newmsgarr.includes(value) && value !==phonenumberRef.current && allowedphonenumbers.includes(value)){
          setNewmsgarr(previous => [...previous, value]);
        }
    }

    socket.on('notification', msgupdateEvent);

    return () => {

        socket.off('notification', msgupdateEvent);
    };
}, [])





  useEffect(() => {
    //console.log('wsss--->', props)
  }, [props])

  const handleInputChange = event => {
    const { name, value } = event.target
    props.fetchAllTenantnumbers({ tenant: value })
    const tenant = props.tenants.find((e) => e.id == value);
    setBusiness(value)
    props.currentTenant(tenant)
  }
  const handlephoneChange = event => {
    const { name, value } = event.target
    const phonenumber = props.tenantnumbers.find((e) => e.phone_number == value);
   // console.log('phonenumber', value)
    setPhonenumber(value)
    phonenumberRef.current = value;
    props.fetchcurrentTenantnumber(phonenumber)
    const filtered = newmsgarr.filter(elem => elem !== value);
    setNewmsgarr(filtered)
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {props?.currentTenantnumber?.tenant?.image?.length && false ? <img src={process.env.REACT_APP_IMG_URL + props?.currentTenantnumber?.tenant?.image} height="30" /> :
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            Pulse CRM
          </Typography>}
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <MenuOpenOutlinedIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>

        <div className={classes.grow} />
        {/* <select class="form-select w-auto form-select-sm mx-2" value={business}
          onChange={handleInputChange}>
          <option selected>Select Business</option>

          {props.tenants.map(item => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select> */}
       {newmsgarr.length>0 && props.tenantnumbers.filter(t =>authUser.role==='agent'? authUser?.tenants?.includes(t.tenant.id): t)?.length>1 && <span className="me-3 shake"> <NotificationsActive /></span>}


        <select className={"form-select  form-select-sm ml-3 " + (isMobile ? "" : "w-auto")} value={phonenumber}
          onChange={handlephoneChange}>
          <option selected >Select Business Number</option>

          {props.tenantnumbers.filter(t =>authUser.role!=='superadmin'? authUser.tenants.includes(t.tenant.id): t).map(item => (
            <option value={item.phone_number}> {item.name} - {item.phone_number}  ({item?.tenant?.name}) {newmsgarr.includes(item.phone_number) ? '🔔' :''}</option>
          ))}
        </select>


        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountCircleIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h6" weight="medium" color="primary">
              {authUser.email}
            </Typography>
            <p className="text-capitalize">{authUser.firstname} - {authUser.role}</p>

          </div>


          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <VpnKeyIcon className={classes.profileMenuIcon} /> Change Password
          </MenuItem> */}

          <div className={classes.logoutMenu}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.logoutMenuButton}
              onClick={() => logout(props.history)}
            >
              Sign Out
            </Button>
          </div>

        </Menu>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = state => ({
  tenants: state.tenant.tenants_all,
  tenantnumbers: state.tenantnumber.tenantnumbers,
  currentTenantnumber: state.tenantnumber.currentTenantnumber,
})

const mapActionToProps = {
  fetchAllTenants: tenants.fetchAll,
  currentTenant: tenants.currentTenant,
  fetchAllTenantnumbers: tenantnumbers.fetchAll,
  fetchcurrentTenantnumber: tenantnumbers.currentTenantnumber

}

export default connect(mapStateToProps, mapActionToProps)(Header);