import React, { useEffect, useState, useContext} from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";
import moment from "moment";
// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import * as msgs from "./../../actions/msg";
import { connect } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  MenuOpenOutlined as MenuOpenOutlinedIcon,
  SendSharp,
  EmojiEmotionsOutlined,
  TextsmsOutlined,
  PagesRounded,
  FormatListBulleted,
  AttachFileOutlined,
  CalendarTodayTwoTone,
  CallMade
} from "@material-ui/icons";
import { AuthContext } from "../../context/AuthContext";

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];
var months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var colors = [
  "",
  "#80637e",
  "#94a0f7",
  "#38b9ab",
  "#f6c252",
  "#a68593",
  "#5bdb3f",
  "#22d092",
  "#688291",
  "#9c5f9e",
  "#f46c55",
  "#4db9d2",
  "#1a586b",
];

function Dashboard(props) {
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()
  var classes = useStyles();
  var theme = useTheme();
  const [analytics, setAnalytics] = useState({});
  const [loading, setLoading] = useState(true);
  const [lastrefreshedtime, setlastrefreshedtime] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const onSuccess = data => {
      setLoading(false);
      setlastrefreshedtime(data);
    };
   // props.getAnalytics(onSuccess);
  }, []);

  useEffect(() => {
   // console.log("analytics----->>", props.analytics);

    setAnalytics(props.analytics);
  }, [props.analytics]);

  const handleRefresh = () => {
    setLoading(true);
    let filter = {
      display_phone_number: props?.currentTenantnumber?.phone_number,
      startDate: startDate,
      endDate: endDate
    }
    const onSuccess = data => {
      setLoading(false);
      setlastrefreshedtime(data);
    };
    props.getAnalytics(onSuccess, filter);
  };

  useEffect(() => {
    setLoading(true);
    const onSuccess = data => {
      setLoading(false);
      setlastrefreshedtime(data);
    };
    if (props?.currentTenantnumber?.phone_number?.length > 0) {
      let filter ={display_phone_number: props?.currentTenantnumber?.phone_number , label:props?.currentTenantnumber?.tenant?.label }
      if(startDate && endDate){
       filter.startDate= startDate
       filter.endDate= endDate
      }
     
      props.getAnalytics(onSuccess,filter);
     // console.log("dashboard109", props?.currentTenantnumber);
    } else {

      if(authUser?.role==='superadmin'){
        let filter = {
          startDate: startDate,
          endDate: endDate
        }
      
        props.getAnalytics(onSuccess,filter?filter:{});
      }
     
     
      
     // console.log("dashboard109", props?.currentTenantnumber);
    }
  }, [props.currentTenantnumber, startDate, endDate]);

  const handleCancel = (event, picker) => { 
    setStartDate(null)
    setEndDate(null)
    picker.element.val(""); picker.startDate = moment(); picker.endDate = moment() 
    handleRefresh()
  };

  const handleCallback=(start, end, label)=> {
    setLoading(true);

    console.log('1', start, end.toDate(), label);
    setStartDate(start)
    setEndDate(end)

//     let filter = {
//       display_phone_number: props?.currentTenantnumber?.phone_number,
//       startDate: start,
//       endDate: end
//     }
// console.log('filters--->', filter)
//        const onSuccess = data => {
//         setLoading(false);
//         setlastrefreshedtime(data);
//       };
//       props.getAnalytics(onSuccess,filter);
  }

  return (
    <>
   {(props?.currentTenantnumber?.phone_number?.length > 0 || authUser.role==='superadmin') ? 
   <>
      {/* <PageTitle title="Dashboard" /> */}
      <div className="row">
        <div className="col-xl-6 col-12">
          <h4>Dashboard {props?.currentTenantnumber?.name?.length>0?' - '+props?.currentTenantnumber?.name:'All Tenants'}</h4>
        </div>
       
        <div className="col-xl-6 col-12">
        <DateRangePicker
            initialSettings={{ startDate: moment().subtract(31, 'days'), endDate: moment(), locale: { cancelLabel: "Clear" }, ranges: {
         
              'Today': [moment(), moment().endOf('day')],
              'Yesterday': [moment().subtract(1, 'days'), moment()],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
           }, }}
           onCallback={handleCallback}
           onCancel={handleCancel}
          >
             <button
            className="btn btn-sm  ms-2 p-0 px-2 float-end mb-3 border-0 btn-secondary">
              <span className="float-start"><CalendarTodayTwoTone style={{fontSize:12, marginTop: 4}} /></span>
              <span className="ms-2">
              {startDate?moment(startDate).format('DD/MM/yy')+ ' - ':'Date Filter'} {endDate?moment(endDate).format('DD/MM/yy')+' ':''} 
              </span>
            
            </button>
          </DateRangePicker>
 
          

          <button
            className="btn btn-danger btn-sm p-0 px-2 float-end mb-3"
            onClick={handleRefresh}
            disabled={loading}
          >
            {loading && (
              <span
                class="spinner-border spinner-border-sm me-1"
                style={{ height: 12, width: 12 }}
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {loading ? "Refreshing..." : "Refresh"}
          </button>

          {!loading && (
            <span className="float-end me-3">
              Refreshed at {moment(lastrefreshedtime).format("hh:mm:ss a")} 
            </span>
          )}
        </div>
      </div>
      <Grid container spacing={4} style={{ opacity: loading ? 0.5 : 1 }}>
      <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            title="Today's Messages"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography size="xl" weight="medium">
                {analytics.todays_msgs}
              </Typography>

              <LineChart
                width={55}
                height={30}
                data={[
                  { value: 10 },
                  { value: 15 },
                  { value: 10 },
                  { value: 17 },
                  { value: 18 },
                ]}
                margin={{ left: theme.spacing(2) }}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </div>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Incoming Messages
                </Typography>
                <Typography size="md">
                  {" "}
                  {analytics.todays_incoming_msgs}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Outgoing Messages
                </Typography>
                <Typography size="md">
                  {" "}
                  {analytics.todays_outgoing_msgs}
                </Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            title="Total Messages"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography size="xl" weight="medium">
                {analytics.total_msgs}
              </Typography>

              <LineChart
                width={55}
                height={30}
                data={[
                  { value: 10 },
                  { value: 15 },
                  { value: 10 },
                  { value: 17 },
                  { value: 18 },
                ]}
                margin={{ left: theme.spacing(2) }}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </div>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Incoming Messages
                </Typography>
                <Typography size="md"> {analytics.incoming_msgs}</Typography>
              </Grid>
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Outgoing Messages
                </Typography>
                <Typography size="md"> {analytics.outgoing_msgs}</Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            title="Total Contacts"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography size="xl" weight="medium">
                {analytics.total_customers}
              </Typography>

              <LineChart
                width={55}
                height={30}
                data={[
                  { value: 10 },
                  { value: 15 },
                  { value: 10 },
                  { value: 17 },
                  { value: 18 },
                ]}
                margin={{ left: theme.spacing(2) }}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </div>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Incoming
                </Typography>
                <Typography size="md">
                  {" "}
                  {analytics.incoming_customers}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="text" colorBrightness="secondary">
                  Outgoing
                </Typography>
                <Typography size="md">
                  {" "}
                  {analytics.outgoing_customers}
                </Typography>
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Widget
              title="Message count by agent ID (captured after 1st May 2024)"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Agent</th>
                    <th scope="col">Email</th>
                    <th scope="col">Business</th>
                    <th scope="col">Msg count</th>
                  </tr>
                </thead>
                <tbody>
                {/* ?.filter(e=> e.email) */}
                  {analytics?.msgs_by_agents?.length?analytics?.msgs_by_agents?.map((item, index) => (
                    <tr>
                      <th scope="col">{index + 1}</th>
                      <th scope="col">{item.displayname?item.displayname:'Bot'}</th>
                      <th scope="col">{item.email?item.email:'-'}</th>
                      <th scope="col">
                        {item?.tenantInfo?.map((data, i) => (
                          <span className="badge bg-success me-1">
                            {data.name}{" "}
                          </span>
                        ))}
                      </th>
                      <th scope="col">{item.count}</th>
                    </tr>
                  )):<>
                <p class="mt-3 mb-0 pb-0">Sorry, No data found, try changing the filters.</p>
                  </>}
                </tbody>
              </table>
            </Widget>
          </Grid>
          <br />
        {authUser?.role==='superadmin' && <Grid item lg={12} md={12} sm={12} xs={12}>
            <Widget
              title="Outgoing message count by Business Number"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Number</th>
                    <th scope="col">Msg count</th>
                  </tr>
                </thead>
                <tbody>
                  {analytics?.msgs_by_display_phone_number
                    ?.filter(e => e.tenantnumbersInfo.length > 0)
                    ?.map((item, index) => (
                      <tr>
                        <th scope="col">{index + 1}</th>
                        <th scope="col">
                          {item?.tenantnumbersInfo?.map((data, i) => (
                            <span className="badge bg-success me-1">
                              {data.name}{" "}
                            </span>
                          ))}
                        </th>
                        <th scope="col">{item._id}</th>
                        <th scope="col">{item.count}</th>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Widget>
          </Grid>}
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Widget
            title="Msg count by agent (Deprecated)"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">Agent</th>
                  <th scope="col">Msg count</th>
                </tr>
              </thead>
              <tbody>
                {analytics?.msgs_by_agents_temp?.length? analytics?.msgs_by_agents_temp?.map((item, index) => (
                  <tr>
                    {/* <th scope="col">{index+1}</th> */}
                    <th scope="col">{item._id || "Unknown"}</th>
                    <th scope="col">{item.count}</th>
                  </tr>
                )):<>
                <p class="mt-3 mb-0 pb-0">Sorry, No data found.</p>
                  </>}
              </tbody>
            </table>
          </Widget>
        </Grid>

        {/* <Grid item lg={3} md={8} sm={6} xs={12}>
          <Widget
            title="App Performance"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.performanceLegendWrapper}>
              <div className={classes.legendElement}>
                <Dot color="warning" />
                <Typography
                  color="text"
                  colorBrightness="secondary"
                  className={classes.legendElementText}
                >
                  Integration
                </Typography>
              </div>
              <div className={classes.legendElement}>
                <Dot color="primary" />
                <Typography
                  color="text"
                  colorBrightness="secondary"
                  className={classes.legendElementText}
                >
                  SDK
                </Typography>
              </div>
            </div>
            <div className={classes.progressSection}>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                Integration
              </Typography>
              <LinearProgress
                variant="determinate"
                value={30}
                classes={{ barColorPrimary: classes.progressBar }}
                className={classes.progress}
              />
            </div>
            <div>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                SDK
              </Typography>
              <LinearProgress
                variant="determinate"
                value={55}
                classes={{ barColorPrimary: classes.progressBar }}
                className={classes.progress}
              />
            </div>
          </Widget>
        </Grid>*/}
        {/* <Grid item lg={6} md={6} sm={6} xs={12}>  
          <Widget
            title="Server Overview"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.serverOverviewElement}>
              <Typography
                color="text"
                colorBrightness="secondary"
                className={classes.serverOverviewElementText}
              >
                60% / 37°С / 3.3 Ghz
              </Typography>
              <div className={classes.serverOverviewElementChartWrapper}>
                <ResponsiveContainer height={50} width="99%">
                  <AreaChart data={getRandomData(10)}>
                    <Area
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.secondary.main}
                      fill={theme.palette.secondary.light}
                      strokeWidth={2}
                      fillOpacity="0.25"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={classes.serverOverviewElement}>
              <Typography
                color="text"
                colorBrightness="secondary"
                className={classes.serverOverviewElementText}
              >
                54% / 31°С / 3.3 Ghz
              </Typography>
              <div className={classes.serverOverviewElementChartWrapper}>
                <ResponsiveContainer height={50} width="99%">
                  <AreaChart data={getRandomData(10)}>
                    <Area
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.primary.main}
                      fill={theme.palette.primary.light}
                      strokeWidth={2}
                      fillOpacity="0.25"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={classes.serverOverviewElement}>
              <Typography
                color="text"
                colorBrightness="secondary"
                className={classes.serverOverviewElementText}
              >
                57% / 21°С / 3.3 Ghz
              </Typography>
              <div className={classes.serverOverviewElementChartWrapper}>
                <ResponsiveContainer height={50} width="99%">
                  <AreaChart data={getRandomData(10)}>
                    <Area
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.warning.main}
                      fill={theme.palette.warning.light}
                      strokeWidth={2}
                      fillOpacity="0.25"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Widget>
        </Grid>  */}

        {/* <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Daily Line Chart
                </Typography>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="warning" />
                    <Typography className={classes.mainChartLegentElement}>
                      Tablet
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Mobile
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Desktop
                    </Typography>
                  </div>
                </div>
                <Select
                  value={mainChartState}
                  onChange={e => setMainChartState(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect,
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <ComposedChart
                margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                data={mainChartData}
              >
                <YAxis
                  ticks={[0, 2500, 5000, 7500]}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <XAxis
                  tickFormatter={i => i + 1}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <Area
                  type="natural"
                  dataKey="desktop"
                  fill={theme.palette.background.light}
                  strokeWidth={0}
                  activeDot={false}
                />
                <Line
                  type="natural"
                  dataKey="mobile"
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Line
                  type="linear"
                  dataKey="tablet"
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid> */}
        {/* {mock.bigStat.map(stat => (
          <Grid item md={4} sm={6} xs={12} key={stat.product}>
            <BigStat {...stat} />
          </Grid>
        ))} */}

        {/* <Grid bodyClass="bg-primary" item xs={12}>
          <Widget
            title="New Orders In Last 24 Hours"
            upperTitle
            noBodyPadding

          >
       
          </Widget>
        </Grid> */}
      </Grid>

      </>:
      <>
      <div class="row h-75">
              <div class="col d-flex align-items-center justify-content-center mt-5">
                <p class="text-center h5 mt-5">Select business number </p>
                <p class="text-center h5 mt-5">
                  <CallMade size={30} style={{ marginTop: -50 }} />
                </p>
              </div>
            </div>
      </>}
    </>
  );
}

const mapStateToProps = state => ({
  analytics: state.msg.analytics,
  currentTenantnumber: state.tenantnumber.currentTenantnumber,
});

const mapActionToProps = {
  getAnalytics: msgs.getAnalytics,
};

export default connect(mapStateToProps, mapActionToProps)(Dashboard);

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
