import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/campaign";
import * as tenants from "../../../actions/tenant";
import * as msgs from "../../../actions/msg";
import * as labels from "../../../actions/label";


import FormDialogAddCampaign from "../formDialog/FormDialogAddCampaign";
import FormDialogEditCampaign from "../formDialog/FormDialogEditCampaign";
import FormDialogDeleteCampaign from "../formDialog/FormDialogDeleteCampaign";
import moment from "moment";
const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const CampaignTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [rowsSelected, setRowsSelected] = useState([])
    // useEffect(() => {
    //     props.fetchPagination(1, rowsPerPage, props?.currentTenantnumber?.phone_number)
    //     props.fetchAllTenants()
    //     props.fetchAllLabels()
    // }, [])

    useEffect(() => {
        if (props?.currentTenantnumber?.phone_number) {
            
            setPage(1)
            props.fetchPagination(1, rowsPerPage, props?.currentTenantnumber?.phone_number)
            props.fetchAllTenants()
            props.fetchAllLabels()

        }

    }, [props.currentTenantnumber])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, props?.currentTenantnumber?.phone_number)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, props?.currentTenantnumber?.phone_number)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, props?.currentTenantnumber?.phone_number, searchText)
    };

    const handleFilterChange = async (firstName, name) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, props?.currentTenantnumber?.phone_number, name)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, props?.currentTenantnumber?.phone_number)
    }

    useEffect(() => {
        if (props.currentTenantnumber.wa_business_id) {
            // socket.emit('switchPhonenumber', props.currentTenantnumber.phone_number, phone_number);
            // setContact({})
            // props.fetchRoomsPagination(1, 50, props.currentTenantnumber.phone_number)
            props.fetchTemplates(props.currentTenantnumber.wa_business_id, 'Bearer ' + props.currentTenantnumber.access_token)
            // setPhone_number(props.currentTenantnumber.phone_number)
        }

    }, [props.currentTenantnumber])

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "template",
            label: "Template",
            options: {
                filter: true,
                sort: false,
             
            },

        },

        {
            name: "labels",
            label: "Labels",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span className={value==='agent'?"badge bg-success text-capitalize":"badge bg-danger text-capitalize"}>{value}</span>
                }
            },

        },

        {
            name: "createdDate",
            label: "Created",

            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                <FormDialogAddCampaign component={Paper}
                                    create={props.create}
                                    refresh={refresh}
                                    tenants={props.tenants}
                                    templates={props.templates}
                                    currentTenantnumber={props.currentTenantnumber}
                                    labels={props.labels}
                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <FormDialogEditCampaign
                                dataCampaign={tableMeta.rowData}
                                getAnalytics={props.getAnalytics}
                                analytics={props.analytics}
                                //tenants={props.tenants}

                            />
                            <FormDialogDeleteCampaign
                                dataCampaign={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,
        onRowsSelect: (rowsSelected, allRows) => {
            console.log(rowsSelected, allRows);
            // this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        },
        rowsSelected: rowsSelected,

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <MUIDataTable className={classes.paperTable}
            data={props.campaigns}
            columns={columns}
            options={options}
            title={"Campaigns"}

        />
    );
}

const mapStateToProps = state => ({
    campaigns: state.campaign.campaigns,
    meta: state.campaign.metaCampaign,
    tenants: state.tenant.tenants_all,
    templates: state.msg.msgstemplates,
    currentTenantnumber: state.tenantnumber.currentTenantnumber,
    labels: state.label.labels,
    analytics: state.campaign.analytics,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllTenants: tenants.fetchAll,
    fetchTemplates: msgs.fetchTemplates,
    fetchAllLabels: labels.fetchAll,
    getAnalytics: actions.getAnalytics,

}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(CampaignTable));