import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  name: "",

}

const FormDialogAddLabel = (props) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setLabel(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setLabel({ ...label, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;
    if (!label.name || label.name.trim() === "") {
      formIsValid = false;
      tempErrors["name"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(label, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
       fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add Label</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          
          <div className="row">
          <div className="col-lg-12 col-12">
              <TextField

                name="name"
                label="Label name"
                value={label.name}
                fullWidth
                onChange={handleInputChange}
                {...(errors.mobile && { error: true, helperText: errors.mobile })}
                style={{ marginBottom: 25 }}
              />
            </div>
          
          </div>
         


        </DialogContent>


        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddLabel;