import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  name: "",
  phone_number: "",
  account_type: "",
  wa_business_id: "",
  app_id: "",
  phone_number_id: "",
  access_token: "",
  message_link: "",
  tenant: "",
  bot_endpoint: '',
  catalog_id: "",
  zoho_refresh_token: "",
  zoho_client_id: "",
  zoho_client_secret: "",
}

const FormDialogAddTenantnumber = (props) => {
  const [open, setOpen] = useState(false);
  const [tenantnumber, setTenantnumber] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setTenantnumber(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setTenantnumber({ ...tenantnumber, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;
    if (!tenantnumber.name || tenantnumber.name.trim() === "") {
      formIsValid = false;
      tempErrors["name"] = "Cannot be empty";
    }
    if (!tenantnumber.phone_number || tenantnumber.phone_number.trim() === "") {
      formIsValid = false;
      tempErrors["phone_number"] = "Cannot be empty";
    }
    if (!tenantnumber.wa_business_id || tenantnumber.wa_business_id.trim() === "") {
      formIsValid = false;
      tempErrors["wa_business_id"] = "Cannot be empty";
    }
    if (!tenantnumber.app_id || tenantnumber.app_id.trim() === "") {
      formIsValid = false;
      tempErrors["app_id"] = "Cannot be empty";
    }
    if (!tenantnumber.phone_number_id || tenantnumber.phone_number_id.trim() === "") {
      formIsValid = false;
      tempErrors["phone_number_id"] = "Cannot be empty";
    }
    if (!tenantnumber.account_type || tenantnumber.account_type.trim() === "") {
      formIsValid = false;
      tempErrors["account_type"] = "Cannot be empty";
    }
    if (!tenantnumber.access_token || tenantnumber.access_token.trim() === "") {
      formIsValid = false;
      tempErrors["access_token"] = "Cannot be empty";
    }
   

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(tenantnumber, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add Business Phone Number</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          
          <div className="row">
          <div className="col-lg-6 col-12">
              <TextField

                name="name"
                label="Name"
                value={tenantnumber.name}
                fullWidth
                onChange={handleInputChange}
                {...(errors.name && { error: true, helperText: errors.name })}
                style={{ marginBottom: 15 }}
                required
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField
                autoFocus
                name="phone_number"
                label="Phone number"
                value={tenantnumber.phone_number}
                fullWidth
                onChange={handleInputChange}
                {...(errors.phone_number && { error: true, helperText: errors.phone_number })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6 col-12">
              <TextField

                name="wa_business_id"
                label="Whatsapp business ID"
                value={tenantnumber.wa_business_id}
                fullWidth
                onChange={handleInputChange}
                {...(errors.wa_business_id && { error: true, helperText: errors.wa_business_id })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField
                name="app_id"
                label="App ID"
                value={tenantnumber.app_id}
                fullWidth
                onChange={handleInputChange}
                {...(errors.app_id && { error: true, helperText: errors.app_id })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
          </div>
        
          <div className="row">
          <div className="col-lg-6 col-12 ">
          <TextField
                name="phone_number_id"
                label="Phone number ID"
                value={tenantnumber.phone_number_id}
                fullWidth
                onChange={handleInputChange}
                {...(errors.phone_number_id && { error: true, helperText: errors.phone_number_id })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  name="account_type"
                  label="Account type"
                  value={tenantnumber.account_type}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value="agent">Agent</MenuItem>
                  <MenuItem value="bot">BOT</MenuItem>
                  <MenuItem value="webbot">Web BOT</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-12">
            {tenantnumber.account_type==='bot' && 
            <>
            <TextField
                name="bot_endpoint"
                label="BOT Endpoint"
                value={tenantnumber.bot_endpoint}
                fullWidth
                onChange={handleInputChange}
                {...(errors.bot_endpoint && { error: true, helperText: errors.bot_endpoint })}
                required
                style={{ marginBottom: 15 }}
              />
             <TextField
                name="catalog_id"
                label="Catalog ID"
                value={tenantnumber.catalog_id}
                fullWidth
                onChange={handleInputChange}
                {...(errors.catalog_id && { error: true, helperText: errors.catalog_id })}
                required
                style={{ marginBottom: 15 }}
              /></>}
            <TextField
                name="access_token"
                label="Access token"
                value={tenantnumber.access_token}
                fullWidth
                onChange={handleInputChange}
                {...(errors.access_token && { error: true, helperText: errors.access_token })}
                required
                style={{ marginBottom: 15 }}
              />
              <TextField
                name="message_link"
                label="Message link"
                value={tenantnumber.message_link}
                fullWidth
                onChange={handleInputChange}
                {...(errors.message_link && { error: true, helperText: errors.message_link })}
                
                style={{ marginBottom: 15 }}
              />

              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Organizations</InputLabel>
                <Select
                  name="tenant"
                  label="Business"
                  value={tenantnumber.tenant}
                  onChange={handleInputChange}
                  required
                >
                  {props.tenants.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>

              <div class="card mt-4">
                <div class="card-header">Zoho Integration</div>
                <div class="card-body">
                 
               
                  <TextField
                name="zoho_refresh_token"
                label="Zoho refresh token"
                value={tenantnumber.zoho_refresh_token}
                fullWidth
                onChange={handleInputChange}
                {...(errors.zoho_refresh_token && {
                  error: true,
                  helperText: errors.zoho_refresh_token,
                })}
                style={{ marginBottom: 15 }}
              />
                <TextField
                name="zoho_client_id"
                label="Zoho client id"
                value={tenantnumber.zoho_client_id}
                fullWidth
                onChange={handleInputChange}
                {...(errors.zoho_client_id && {
                  error: true,
                  helperText: errors.zoho_client_id,
                })}
                style={{ marginBottom: 15 }}
              />
                <TextField
                name="zoho_client_secret"
                label="Zoho client secret"
                value={tenantnumber.zoho_client_secret}
                fullWidth
                onChange={handleInputChange}
                {...(errors.zoho_client_secret && {
                  error: true,
                  helperText: errors.zoho_client_secret,
                })}
                style={{ marginBottom: 15 }}
              />
                </div>
              </div>
            </div>
          </div>
          


        </DialogContent>


        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddTenantnumber;