import { ACTION_TYPES } from "../actions/customer";

const initialState = {
    customers: [],
    metaCustomer: {}
}

export const customer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CUSTOMER_FETCH_ALL:
            return {
                ...state,
                customers: [...action.payload]
            }
        case ACTION_TYPES.CUSTOMER_CREATE:
            return {
                ...state,
                customers: [...state.customers, action.payload]
            }
        case ACTION_TYPES.CUSTOMER_UPDATE:
            return {
                ...state,
                customers: state.customers.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.CUSTOMER_DELETE:
            return {
                ...state,
                customers: state.customers.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.CUSTOMER_PAGINATION:

            return {
                ...state,
                customers: [...action.payload.customers],
                metaCustomer: action.payload.meta
            }
            case ACTION_TYPES.CUSTOMER_PAGINATION1:

            return {
                ...state,
                customers: [...state.customers, ...action.payload.customers],
                metaCustomer: action.payload.meta
            }
        case ACTION_TYPES.CUSTOMER_BULK:
            return {
                ...state,
                customers: [...state.customers, action?.payload?.insertedDocs]
            }
        default:
            return state;
    }
}