import API from "../utils/api";

export const ACTION_TYPES = {
    MSG_CREATE: 'MSG_CREATE',
    MSG_UPDATE: 'MSG_UPDATE',
    MSG_DELETE: 'MSG_DELETE',
    MSG_FETCH: 'MSG_FETCH',
    MSG_FETCH_ALL: 'MSG_FETCH_ALL',
    MSG_PAGINATION: 'MSG_PAGINATION',
    MSG_SENT: 'MSG_SENT',
    MSG_FETCH_TEMPLATES: 'MSG_FETCH_TEMPLATES',
    MSG_FETCH_PRODUCTS: 'MSG_FETCH_PRODUCTS',
    MSG_FETCH_ANALYTICS: 'MSG_FETCH_ANALYTICS',
    MSG_UPDATE_ACKS: 'MSG_UPDATE_ACKS'
}

export const fetchAll = () => dispatch => {
    API.msg().fetchAll()
        .then(res => {

            dispatch({
                type: ACTION_TYPES.MSG_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 15, mobile = "", phone_number_id = "") => dispatch => {
    console.log(mobile, phone_number_id, 'mobile, phone_number_id')
    API.msg().fetchPagination(page, Math.abs(limit), mobile, phone_number_id)
        .then(res => {
            console.log('1212', res.data)

            dispatch({
                type: ACTION_TYPES.MSG_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.msg().fetchById(id)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.MSG_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.msg().create(input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.MSG_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.msg().update(id, input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.MSG_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update_acks = (data) => dispatch => {

    dispatch({
        type: ACTION_TYPES.MSG_UPDATE_ACKS,
        payload: data
    })
}

export const Delete = (id, onSuccess) => dispatch => {
    API.msg().delete(id)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.MSG_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const sendwsmsg = (input) => dispatch => {
    API.msg().sendwsmsg(input)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.MSG_SENT,
                payload: res.data
            })

        })
        .catch(err => console.log(err))
}
export const sendbotmsg = (input) => dispatch => {
    API.msg().sendbotmsg(input)
        .then(res => {
            
            dispatch({
                type: ACTION_TYPES.MSG_SENT,
                payload: res.data
            })

        })
        .catch(err => console.log(err))
}

export const fetchTemplates = (input, token) => dispatch => {

    API.msg().gettemplates(input, token)
        .then(res => {
console.log('fetchTemplates',res.data)
            dispatch({
                type: ACTION_TYPES.MSG_FETCH_TEMPLATES,
                payload: res.data.data
            })
        })
        .catch(err => console.log('fetchTemplates',err))
}

export const fetchProducts = (catalog_id, token, searchkeyword) => dispatch => {
    API.msg().getproducts(catalog_id, token, searchkeyword)
        .then(res => {
            console.log('prods', res.data)
            dispatch({
                type: ACTION_TYPES.MSG_FETCH_PRODUCTS,
                payload: res.data.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchsocketmsg = (input) => dispatch => {
    dispatch({
        type: ACTION_TYPES.MSG_SENT,
        payload: input
    })
}

export const getAnalytics = (onSuccess, filter) => dispatch => {
    API.msg().getAnalytics(filter)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.MSG_FETCH_ANALYTICS,
                payload: res.data
            })
            onSuccess(new Date())
        })
        .catch(err => console.log(err))
}