import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  business_id: "",
  name: "",
  description: "",
  website: ""
}

const FormDialogEditTenant = (props) => {
  const [open, setOpen] = useState(false);
  const [tenant, setTenant] = useState(initialFormState);
  const [errors, setErrors ] = useState({})

  const handleClickOpen = () => {
      setErrors({});
      setTenant({
        id : props.dataTenant[0],
        image : props.dataTenant[1],
        business_id : props.dataTenant[2],
        name : props.dataTenant[3],
        description : props.dataTenant[4],
        website : props.dataTenant[5]
      })
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  }

  const handleInputChange = event => {
		const { name, value } = event.target
    setTenant({ ...tenant, [name]: value })
  }
  
  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 150) {
      toast.warning('maximum size allowed: 150kb');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setTenant({ ...tenant, image: res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!tenant.business_id || tenant.business_id.trim() === "") {
      formIsValid = false;
      tempErrors["business_id"] = "Cannot be empty";
    }

    if (!tenant.name || tenant.name.trim() === "") {
      formIsValid = false;
      tempErrors["name"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
      const onSuccess = () => {
          setOpen(false);
          toast.success('Data succesfully updated');
      }
      e.preventDefault();

      if(validate()){
        props.update(tenant.id, tenant, onSuccess)
      }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
          <EditIcon />
      </IconButton>
      <Dialog  
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
            <DialogTitle id="form-dialog-title" style={{padding: "30px 30px 0px 30px"}}>Edit Tenant</DialogTitle>

            <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <div className="row">
            <div className="col-lg-12 col-12 mb-4">
              <Button variant="contained" component="label">
                Upload Picture <sub>&nbsp; 150kb max</sub>
                <input
                  type="file"
                  name="file"
                  label="Image"
                  accept=".png,.jpg,.gif"
                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button>
              {tenant.image.length > 0 && <img src={process.env.REACT_APP_IMG_URL + tenant.image} height="40" style={{ marginTop: "-30px", float: "right" }} />}
            </div>
          </div>
      
          <TextField
            autoFocus
            name="business_id"
            label="Business ID"
            value={tenant.business_id}
            fullWidth
            onChange={handleInputChange}
            {...(errors.business_id && { error: true, helperText: errors.business_id })}
            required
          />
  <br /><br />
          <TextField

            name="name"
            label="Name"
            value={tenant.name}
            fullWidth
            onChange={handleInputChange}
            {...(errors.name && { error: true, helperText: errors.name })}
            required
          />

          <br /><br />

          <TextField
            name="description"
            label="Description"
            value={tenant.description}
            fullWidth
            onChange={handleInputChange}
            {...(errors.description && { error: true, helperText: errors.description })}
          />

          <br /><br />

          <TextField
            name="website"
            label="Website URL"
            value={tenant.website}
            fullWidth
            onChange={handleInputChange}
            {...(errors.website && { error: true, helperText: errors.website })}
          />

        </DialogContent>

            <DialogActions style={{padding: 30}}>
                <Button variant="contained" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit} color="secondary">
                    Save
                </Button>
            </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditTenant;