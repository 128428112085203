import API from "../utils/api";
import Cookies from "js-cookie";

export const ACTION_TYPES = {
    TENANTNUMBER_CREATE: 'TENANTNUMBER_CREATE',
    TENANTNUMBER_UPDATE: 'TENANTNUMBER_UPDATE',
    TENANTNUMBER_DELETE: 'TENANTNUMBER_DELETE',
    TENANTNUMBER_FETCH: 'TENANTNUMBER_FETCH',
    TENANTNUMBER_FETCH_ALL: 'TENANTNUMBER_FETCH_ALL',
    TENANTNUMBER_PAGINATION: 'TENANTNUMBER_PAGINATION',
    TENANTNUMBER_CURRENT: 'TENANTNUMBER_CURRENT'
}

export const fetchAll = () => dispatch => {
    API.tenantnumber().fetchAll()
        .then(res => {
            dispatch({
                type: ACTION_TYPES.TENANTNUMBER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, firstName = "", email = "") => dispatch => {
    API.tenantnumber().fetchPagination(page, Math.abs(limit), firstName, email)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.TENANTNUMBER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.tenantnumber().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.TENANTNUMBER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.tenantnumber().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.TENANTNUMBER_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.tenantnumber().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.TENANTNUMBER_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.tenantnumber().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.TENANTNUMBER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const currentTenantnumber = (data) => dispatch => {

    Cookies.set('currentTenantnumber', JSON.stringify(data))
    dispatch({
        type: ACTION_TYPES.TENANTNUMBER_CURRENT,
        payload: data
    })
}