import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/tenantnumber";
import * as tenants from "../../../actions/tenant";

import FormDialogAddTenantnumber from "../formDialog/FormDialogAddTenantnumber";
import FormDialogEditTenantnumber from "../formDialog/FormDialogEditTenantnumber";
import FormDialogDeleteTenantnumber from "../formDialog/FormDialogDeleteTenantnumber";
import moment from "moment";
const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const TenantnumberTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)
        props.fetchAllTenants()

    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (firstName, email) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, firstName, email)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "phone_number",
            label: "Phone Number",
            options: {
                filter: true,
                sort: false,
            },
            
        },
        {
            name: "account_type",
            label: "Type",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span className={value==='agent'?"badge bg-success text-capitalize":"badge bg-danger text-capitalize"}>{value}</span>
                }
                
            },
        },
        {
            name: "wa_business_id",
            label: "wa_business_id",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        
        {
            name: "app_id",
            label: "app_id",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "phone_number_id",
            label: "phone_number_id",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "access_token",
            label: "access_token",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "message_link",
            label: "message_link",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "tenant",
            label: "Business",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span><img src={process.env.REACT_APP_IMG_URL + value.image} height="15" />  {value?.business_id}</span>
                }
            }
        },
        {
            name: "bot_endpoint",
            label: "bot_endpoint",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "catalog_id",
            label: "catalog_id",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "zoho_refresh_token",
            label: "zoho_refresh_token",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "zoho_client_id",
            label: "zoho_client_id",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "zoho_client_secret",
            label: "zoho_client_secret",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "createdDate",
            label: "Created",
            
            options: {
                filter: true,
                sort: false,
                display:false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddTenantnumber component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    tenants={props.tenants}

                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            <FormDialogEditTenantnumber
                                dataTenantnumber={tableMeta.rowData}
                                update={props.update}
                                tenants={props.tenants}

                            />
                            <FormDialogDeleteTenantnumber 
                                dataTenantnumber={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'vertical',
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,

        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.tenantnumbers}
            columns={columns}
            options={options}
            title={"Business Phone Numbers"}
        />
    );
}

const mapStateToProps = state => ({
    tenantnumbers: state.tenantnumber.tenantnumbers,
    meta: state.tenantnumber.metaTenantnumber,
    tenants: state.tenant.tenants_all,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllTenants: tenants.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(TenantnumberTable));