import API from "../utils/api";

export const ACTION_TYPES = {
    CAMPAIGN_CREATE: 'CAMPAIGN_CREATE',
    CAMPAIGN_UPDATE: 'CAMPAIGN_UPDATE',
    CAMPAIGN_DELETE: 'CAMPAIGN_DELETE',
    CAMPAIGN_FETCH: 'CAMPAIGN_FETCH',
    CAMPAIGN_FETCH_ALL: 'CAMPAIGN_FETCH_ALL',
    CAMPAIGN_PAGINATION: 'CAMPAIGN_PAGINATION',
    CAMPAIGN_FETCH_ANALYTICS: 'CAMPAIGN_FETCH_ANALYTICS'
}

export const fetchAll = () => dispatch => {
    API.campaign().fetchAll()
        .then(res => {
            dispatch({
                type: ACTION_TYPES.CAMPAIGN_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, business_mobile = "", name = "") => dispatch => {
    console.log('business_mobile',business_mobile)
    API.campaign().fetchPagination(page, Math.abs(limit), business_mobile, name)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CAMPAIGN_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.campaign().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CAMPAIGN_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.campaign().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CAMPAIGN_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.campaign().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CAMPAIGN_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.campaign().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CAMPAIGN_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const getAnalytics = (onSuccess, filter) => dispatch => {
    API.campaign().getAnalytics(filter)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.CAMPAIGN_FETCH_ANALYTICS,
                payload: res.data
            })
            onSuccess(new Date())
        })
        .catch(err => console.log(err))
}