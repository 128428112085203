import { ACTION_TYPES } from "../actions/msg";

const initialState = {
    msgs: [],
    metaMsg: {},
    msgstemplates: [],
    products:[],
    analytics: {}
}

export const msg = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.MSG_FETCH_ALL:
            return {
                ...state,
                msgs: [...action.payload]
            }
        case ACTION_TYPES.MSG_CREATE:
            return {
                ...state,
                msgs: [...state.msgs, action.payload]
            }
        case ACTION_TYPES.MSG_SENT:
            return {
                ...state,
                msgs: [...state.msgs, action.payload]
            }
        case ACTION_TYPES.MSG_UPDATE:
            return {
                ...state,
                msgs: state.msgs.map(x => x.id === action.payload.id ? action.payload : x)
            }
            case ACTION_TYPES.MSG_UPDATE_ACKS:
                return {
                    ...state,
                    msgs: state.msgs.map(x => x.msgid === action.payload.msgid ? action.payload : x)
                }
        case ACTION_TYPES.MSG_DELETE:
            return {
                ...state,
                msgs: state.msgs.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.MSG_PAGINATION:

            return {
                ...state,
                msgs: action.payload.meta.page === 1 ? action.payload.msgs : [...action.payload.msgs, ...state.msgs],
                metaMsg: action.payload.meta
            }
        case ACTION_TYPES.MSG_FETCH_TEMPLATES:
            return {
                ...state,
                msgstemplates: [...action.payload]
            }
        case ACTION_TYPES.MSG_FETCH_PRODUCTS:
            return {
                ...state,
                products: [...action.payload]
            }
            case ACTION_TYPES.MSG_FETCH_ANALYTICS:
                return {
                    ...state,
                    analytics: action.payload
                }
        default:
            return state;
    }
}