import API from "../utils/api";

export const ACTION_TYPES = {
    CUSTOMER_CREATE: 'CUSTOMER_CREATE',
    CUSTOMER_UPDATE: 'CUSTOMER_UPDATE',
    CUSTOMER_DELETE: 'CUSTOMER_DELETE',
    CUSTOMER_FETCH: 'CUSTOMER_FETCH',
    CUSTOMER_FETCH_ALL: 'CUSTOMER_FETCH_ALL',
    CUSTOMER_PAGINATION: 'CUSTOMER_PAGINATION',
    CUSTOMER_PAGINATION1: 'CUSTOMER_PAGINATION1',
    CUSTOMER_BULK: 'CUSTOMER_BULK'
}

export const fetchAll = () => dispatch => {
    API.customer().fetchAll()
        .then(res => {
            dispatch({
                type: ACTION_TYPES.CUSTOMER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, firstName = "", email = "") => dispatch => {
    API.customer().fetchPagination(page, Math.abs(limit), firstName, email)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CUSTOMER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}
export const virtualscroll = (page = 1, limit = 5, firstName = "", email = "") => dispatch => {
    API.customer().fetchPagination(page, Math.abs(limit), firstName, email)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CUSTOMER_PAGINATION1,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.customer().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CUSTOMER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.customer().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CUSTOMER_CREATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err =>  onSuccess(false))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.customer().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CUSTOMER_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.customer().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CUSTOMER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}


export const bulkupload = (input, onSuccess) => dispatch => {
    
    API.customer().bulkupload(input)
        .then(res =>{
            console.log('bulkupload',res.data)
            dispatch({
                type: ACTION_TYPES.CUSTOMER_BULK,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}