import API from "../utils/api";
import Cookies from "js-cookie";
export const ACTION_TYPES = {
    TENANT_CREATE: 'TENANT_CREATE',
    TENANT_UPDATE: 'TENANT_UPDATE',
    TENANT_DELETE: 'TENANT_DELETE',
    TENANT_FETCH: 'TENANT_FETCH',
    TENANT_FETCH_ALL: 'TENANT_FETCH_ALL',
    TENANT_PAGINATION: 'TENANT_PAGINATION',
    TENANT_CURRENT: 'TENANT_CURRENT',

}

export const fetchAll = () => dispatch => {
    API.tenant().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.TENANT_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, name = "") => dispatch => {
    API.tenant().fetchPagination(page, Math.abs(limit), name)
        .then(res =>{
         //  console.log('1212',res.data)
            dispatch({
                type: ACTION_TYPES.TENANT_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.tenant().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.TENANT_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.tenant().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.TENANT_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.tenant().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.TENANT_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.tenant().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.TENANT_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const currentTenant = (data) => dispatch => {

    Cookies.set('currentTenant', JSON.stringify(data))

    dispatch({
        type: ACTION_TYPES.TENANT_CURRENT,
        payload: data
    })
}