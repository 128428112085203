import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect

} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import Dashboard from "../../pages/dashboard/Dashboard";
import User from "../../pages/user";
import Tenant from "../../pages/tenant";
import Customer from "../../pages/customer";
import Tenantnumber from "../../pages/tenantnumber";
import Inbox from "../../pages/inbox";
import Campaign from "../../pages/campaigns";
import Label from "../../pages/label";

// user with context
import UserWithContext from "../../pages/user-context";
import AddUserForm from "../../pages/user-context/form/AddUserForm";
import EditUserForm from "../../pages/user-context/form/EditUserForm";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { UserProvider } from "../../pages/user-context/context/UserContext";
import { AuthContext } from "../../context/AuthContext";

function AdminLayout(props) {
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()

  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  const RoleRoute = ({ role, roles = [], ...props }) => {
    return !roles.length || roles.includes(role) ? (
      <Route {...props} />
    ) : (
      <Redirect to="/admin/inbox/" />
    );
  };

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>

             <RoleRoute
              path="/admin/dashboard/"
              component={Dashboard}
              role={authUser.role}
              roles={["superadmin", "admin"]}
            />

            <RoleRoute
              path="/admin/inbox/"
              component={Inbox}
              role={authUser.role}
              roles={["superadmin", "admin", "agent"]}
            />
            
            <RoleRoute
              path="/admin/campaign/"
              component={Campaign}
              role={authUser.role}
              roles={["superadmin", "admin"]}
            />

            <RoleRoute
              path="/admin/customer/"
              component={Customer}
              role={authUser.role}
              roles={["superadmin", "admin"]}
            />

            <RoleRoute
              path="/admin/label/"
              component={Label}
              role={authUser.role}
              roles={["superadmin", "admin"]}
            />

            <RoleRoute
              path="/admin/user/"
              component={User}
              role={authUser.role}
              roles={["superadmin"]}
            />

            <RoleRoute
              path="/admin/tenant/"
              component={Tenant}
              role={authUser.role}
              roles={["superadmin"]}
            />

            <RoleRoute
              path="/admin/tenant-numbers/"
              component={Tenantnumber}
              role={authUser.role}
              roles={["superadmin"]}
            />

            {/* <Route exact path="/admin/user/" component={User} /> */}
            {/* <Route exact path="/admin/tenant/" component={Tenant} /> */}
            {/* <Route exact path="/admin/tenant-numbers/" component={Tenantnumber} /> */}
            {/* <Route exact path="/admin/customer/" component={Customer} /> */}
            {/* <Route exact path="/admin/inbox/" component={Inbox} />  */}
            {/* <Route exact path="/admin/campaign/" component={Campaign} /> */}
            {/* <Route exact path="/admin/label/" component={Label} /> */}


            {/* <UserProvider>
                  <Route exact path="/admin/usercontext" component={UserWithContext} />
                  <Route path="/admin/usercontext/add" component={AddUserForm} />
                  <Route path="/admin/usercontext/edit/:id" component={EditUserForm} />
                </UserProvider> */}

          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(AdminLayout);
