import { Virtuoso } from "react-virtuoso";
import React, {
  
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useContext
} from "react";
import useState from 'react-usestateref'

import * as msgs from "./../../actions/msg";
import { connect } from "react-redux";
import moment from "moment";
import wsbg from "./../../images/wsbg.png";
import { socket } from "../../context/socket";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  MenuOpenOutlined as MenuOpenOutlinedIcon,
  SendSharp,
  EmojiEmotionsOutlined,
  TextsmsOutlined,
  PagesRounded,
  FormatListBulleted,
  AttachFileOutlined,
  DoneAll,
  Done
  
} from "@material-ui/icons";
import EmojiPicker from "emoji-picker-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ReactHtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import API from "../../utils/api";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import PreviewModal from "react-media-previewer";
import { AuthContext } from "../../context/AuthContext";
import { green } from "@material-ui/core/colors";

const Chatbox = props => {
  const inputEl = useRef(null);
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()
  console.log('authUser',authUser)
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputext, setInputext] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstItemIndex, setFirstItemIndex] = useState(1);
  const [set, setset] = useState(true);
  const [isOlderThan24Hours, setisOlderThan24Hours] = useState(false);
  const [issmiley, setissmiley] = useState(false);
  const [istemplate, setistemplate] = useState(false);
  const [isproduct, setisproduct] = useState(false);

  const [currentTemplate, setcurrentTemplate] = useState({});
  const [prodlist, setprodlist] = useState([]);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [socketroom, setSocketroom] = useState("");
  const [tempheaderimage, settempheaderimage] = useState("");
  const [showmediamenu, setshowmediamenu] = useState(false);
  const [ismediaupload, setismediaupload] = useState(false);
  const [file, setfile] = useState([]);
  const [acceptedFileType, setAcceptedFileType] = useState({});
  const [mediatype, setmediatype] = useState("");
  const [previewmedia, setPreviewmedia] = useState(false);
  const [mediaurl, setmediaurl] = useState("");
  const [files, setFiles] = useState([]);


  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,

    onDrop: acceptedFiles => {
      console.log("acceptedFiles", acceptedFiles);
      if (acceptedFiles[0].size / 1024 > 5120) {
        toast.error("maximum file size allowed: 5mb");
        return;
      }
      var formdata = new FormData();
      formdata.append("file", acceptedFiles[0]);
      formdata.append("documentId", Date.now());
      API.utility()
        .upload(formdata)
        .then(res => {
          console.log(res.data.data.path);
          settempheaderimage(res.data.data.path);
        });
      setfile(acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(() => {
    // function onConnect() {
    //     setIsConnected(true);
    // }

    // function onDisconnect() {
    //     setIsConnected(false);
    // }
   
    function msgupdateEvent(value, isack) {
     // console.log('msgupdate11',value, isack, props?.currentTenantnumber, props.contact)
      if(value.phone_number_id===props?.currentTenantnumber?.phone_number_id  && value.wa_id===props.contact._id){
      if(isack){
        // setChats(prevChats =>
        //   prevChats.map(chat =>
        //     chat.msgid === value.msgid ? { ...chat, ack: value.ack } : chat
        //   )
        // );
       props.update_acks(value)
      }else{

      setisOlderThan24Hours(false);
      props.fetchsocketmsg(value);
      //setChats(previous => [...previous, value]);
      }
    }
    }

    // socket.on('connect', onConnect);
    // socket.on('disconnect', onDisconnect);
    socket.on("msgupdate", msgupdateEvent);

    return () => {
      // socket.off('connect', onConnect);
      // socket.off('disconnect', onDisconnect);
      socket.off("msgupdate", msgupdateEvent);
    };
  }, []);

  useEffect(() => {
    setPage(1);
    setFirstItemIndex(1);
    setIsLoading(false);
    // console.log('props.contact',props.contact.blocked.includes(props?.currentTenantnumber?.id))
    setChats([]);
    props.fetchmsgs(
      1,
      rowsPerPage,
      props.contact.mobile.replace(/\+/g),
      props?.currentTenantnumber?.phone_number_id,
    );
    // socket.emit('disconnectRoom', props.contact.mobile.replace(/\+/g,)+props?.currentTenantnumber?.phone_number_id);
    socket.emit(
      "switchRoom",
      props.contact.mobile.replace(/\+/g) +
        props?.currentTenantnumber?.phone_number_id,
      socketroom,
    );
    setSocketroom(
      props.contact.mobile.replace(/\+/g) +
        props?.currentTenantnumber?.phone_number_id,
    );
  }, [props.contact]);

  // useEffect(() => {
  //     console.log(props.templates, 'sssss')
  // }, [props.templates]);

  useEffect(() => {
    if (props.msgs.length) {
      setChats(props.msgs);
      // setChats(previous => [...previous, props.msgs]);
    }

    if (page === 1) {
      setisOlderThan24Hours(props?.metamsgs?.isOlderThan24Hours);
    }
  }, [props.msgs]);

  useEffect(() => {
    if (props.metamsgs.totalDocs > 0 && set) {
      setFirstItemIndex(props.metamsgs.totalDocs - rowsPerPage);
      setset(false);
    }
  }, [props.metamsgs.totalDocs]);

  const handleChangePage = async () => {
    setPage(page + 1);
    props.fetchmsgs(
      page + 1,
      rowsPerPage,
      props.contact.mobile.replace(/\+/g, ""),
      props?.currentTenantnumber?.phone_number_id,
    );
  };

  const prependItems = useCallback(() => {
    if (firstItemIndex < -rowsPerPage) {
      setIsLoading(true);
      return true;
    }

    setTimeout(() => {
      setFirstItemIndex(firstItemIndex - rowsPerPage);
      handleChangePage();
    }, 500);
    // console.log("prepending items", firstItemIndex);
    return false;
  }, [firstItemIndex]);

  const sendMsg = (type, format) => {
     console.log('authUser', authUser)

    let msgobj = {
      wa_id: props.contact.mobile.replace(/\+/g, ""),
      from: authUser?.firstname?authUser?.firstname:"",
      timestamp: moment().format("x"),
      body: inputext,
      usertype: "agent", //bot/agent
      ack: "",
      phone_number_id: props?.currentTenantnumber?.phone_number_id,
      display_phone_number: props?.currentTenantnumber?.phone_number,
      token: props?.currentTenantnumber?.access_token,
      type: type,
      sender: authUser?.id
    };

    switch (type) {
      case "text":
        msgobj.text = { body: inputext };
        break;
      case "template":
        if (
          (format === "image" || format === "video" || format === "document") &&
          tempheaderimage.length < 2
        ) {
          toast.error(format + " can not be empty.");
          return;
        }
        msgobj.template = {
          name: currentTemplate.name,
          language: { code: currentTemplate.language },
          components:
            tempheaderimage.length && format
              ? [
                  {
                    type: "header",
                    parameters: [
                      {
                        type: format,
                        [format]: {
                          link: tempheaderimage,
                        },
                      },
                    ],
                  },
                ]
              : [],
        };
        // console.log('currentTemplate', currentTemplate)

        msgobj.body = currentTemplate.components.map(e => e.text).join("<br>");
        if (format === "image") {
          msgobj.body =
            '<div class="header"><img src=' +
            tempheaderimage +
            ' class="w-100"></div>' +
            msgobj.body;
        }
        if (format === "video") {
          msgobj.body =
            '<div class="header"><video class="w-100" height="240" src=' +
            tempheaderimage +
            " control></video></div>" +
            msgobj.body;
        }
        if (format === "document") {
          msgobj.body =
            '<div class="header"> <embed src=' +
            tempheaderimage +
            ' class="w-100" height="200" /></div>' +
            msgobj.body;
        }

        settempheaderimage("");
        closeTemplateModal();
        break;
      case "interactive":
        msgobj.interactive = {
          type: "product_list",
          header: {
            type: "text",
            text: "Here is your products",
          },
          body: {
            text: "Click on view items to add products to cart.",
          },
          action: {
            catalog_id: "706946281414707",
            sections: [
              {
                title: "Sample Title",
                product_items: prodlist.map(product => {
                  return {
                    product_retailer_id: product.retailer_id,
                  };
                }),
              },
            ],
          },
        };
        //  console.log('prodlist', prodlist)
        if (!prodlist.length) {
          toast("Please select atleast one product.");
          return;
        }
        msgobj.body = prodlist
          .map(e => e.name + " - " + e.retailer_id)
          .join("<br>");
        msgobj.body =
          msgobj.body +
          "<br><br> Here is the list of products you are looking for.";
        msgobj.body =
          msgobj.body +
          "<hr /> Whom do you want to continue the further conversation with? <br/>";
        msgobj.body = msgobj.body + '<a class="btn btn-link"> Bot</a>';
        msgobj.body = msgobj.body + '<a class="btn btn-link"> Agent</a>';

        msgobj.isbotconfimation = true;
        setisproduct(false);
        setprodlist([]);
        break;
      case "media":
        if (tempheaderimage.length < 2) {
          toast.warning("Couldn't upload the file. please try again");
          return;
        }
        msgobj.type = mediatype;

        if (mediatype === "document") {
          msgobj[mediatype] = {
            link: process.env.REACT_APP_IMG_URL + tempheaderimage,
            filename: tempheaderimage.split("/").pop(),
          };
        } else {
          msgobj[mediatype] = {
            link: process.env.REACT_APP_IMG_URL + tempheaderimage,
          };
        }
        msgobj.body = tempheaderimage;
        setismediaupload(false);
        settempheaderimage("");
        break;
    }

    console.log("msgobj", msgobj);

    setInputext("");
    props.sendMsg(msgobj, null);
    setissmiley(false);
    return true;
  };
  const sendbotmsg = () => {
    let msgobj = {
      "botid": props?.currentTenantnumber?.phone_number_id,
      "body": inputext,
      "usertype": "agent",
      "isagent": "yes",
      "type": "text",
      "mobile": props.contact.mobile.replace(/\+/g, ""),
      "isnewuser": false, // or false
      "name": authUser?.firstname?authUser?.firstname:"",
      "from": authUser?.firstname?authUser?.firstname:"",
      "email": "",
      "sender": authUser?.id
    }
    setInputext("");
    props.sendbotmsg(msgobj);
    setissmiley(false);
    return true;
  }

  const updatetempprodarray = product => {
    if (prodlist.findIndex(x => x.retailer_id === product.retailer_id) === -1) {
      setprodlist(previous => [...previous, product]);
    } else {
      const updatedProducts = prodlist.filter(
        e => e.retailer_id !== product.retailer_id,
      );
      setprodlist(updatedProducts);
    }
  };

  useEffect(() => {
    // console.log(prodlist)
  }, [prodlist]);

  const closeTemplateModal = () => {
    setistemplate(false);
    setcurrentTemplate({});
  };
  const closeTemplateModalproduct = () => {
    setisproduct(false);
    //setcurrentTemplate({})
  };

  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 1024) {
      toast.warning("maximum size allowed: 1mb");
      return;
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility()
      .upload(formdata)
      .then(res => {
        settempheaderimage(process.env.REACT_APP_IMG_URL + res.data.data.path);
      });
  };

  const showmediapreview = url => {
    setmediaurl(url);
    setPreviewmedia(true);
  };

  const handleCloseMenu = type => {
    setshowmediamenu(false);
    setismediaupload(true);
    setFiles([]);
    setmediatype(type);
    switch (type) {
      case "image":
        setAcceptedFileType({
          "image/jpeg": [".jpeg"],
          "image/png": [".png"],
        });
        break;

      case "video":
        setAcceptedFileType({
          "video/mp4": [".mp4"],
          "video/3gp": [".3gp"],
        });
        break;

      case "document":
        setAcceptedFileType({
          "application/pdf": [".pdf"],
          "text/csv": [".csv"],
          "text/plain": [".txt"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
            ".docx",
          ],
          "application/msword": [],
          "application/vnd.ms-excel": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
        });
        break;
      case "sticker":
        setAcceptedFileType({
          "image/webp": [".webp"],
        });
        break;
    }
  };

  const thumbs = files.map(file => (
    <div className="row" key={file.name}>
      <div className="col">
        {(mediatype === "image" && (
          <div>
            <img
              src={process.env.REACT_APP_IMG_URL + tempheaderimage}
              height="200"
              className="mt-2 w-100"
              style={{ objectFit: "contain" }}
            />
          </div>
        )) ||
          (mediatype === "video" && (
            <div>
              <video
                src={process.env.REACT_APP_IMG_URL + tempheaderimage}
                width="300"
                height="200"
                className="mt-2"
                controls
              />
            </div>
          )) ||
          (mediatype === "document" && (
            <div>
              <embed
                src={process.env.REACT_APP_IMG_URL + tempheaderimage}
                className="w-100 mt-2"
                height="200"
              />
            </div>
          ))}

        <div className="small mt-3" style={{ wordBreak: "break-word" }}>
          {file.name}
        </div>
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <>
      {chats.length > 0 ? (
        <Virtuoso
          components={{
            Header: () => (
              <div style={{ textAlign: "center", padding: "1rem" }}>
                {isLoading ? "End" : "Loading..."}
              </div>
            ),
            EmptyPlaceholder: () => {
              return (
                <div style={{ textAlign: "center", padding: "1rem" }}>
                  No Messages
                </div>
              );
            },
          }}
          style={{
            height: "57vh",
            overflowX: "hidden",
            //backgroundImage: `url(require('./../../images/wsbg.png'))`,
            flex: 1,
            backgroundImage: `url(${wsbg})`
          }}
          firstItemIndex={firstItemIndex}
          initialTopMostItemIndex={chats.length - 1}
          data={chats}
          startReached={prependItems}
          alignToBottom={true}
          followOutput="auto"
          itemContent={(index, data) => {
            return (
              <li
                className={
                  data.usertype === "user" ? "sender ms-2" : "repaly me-2"
                }
                key={index}
              >
                <p>
                  {(data.type === "image" && (
                    <div>
                    <a onClick={()=>showmediapreview(process.env.REACT_APP_IMG_URL + data.body)}>
                      <img
                        src={process.env.REACT_APP_IMG_URL + data.body}
                        height="200"
                        className="mt-2 w-100"
                        style={{ objectFit: "contain" }}
                      />
                      </a>
                    </div>
                  )) ||
                    (data.type === "video" && (
                      <div><a onClick={()=>showmediapreview(process.env.REACT_APP_IMG_URL + data.body)}>
                        <video
                          src={process.env.REACT_APP_IMG_URL + data.body}
                          width="300"
                          height="200"
                          className="mt-2"
                          controls={false}
                        />
                        </a>
                      </div>
                    )) ||
                    (data.type === "document" && (
                      <div>
                       

                        
                       
                        <embed
                          src={process.env.REACT_APP_IMG_URL + data.body}
                          className="w-100 mt-2"
                          height="200"
                        />
                        <a className="w-100 h-100" onClick={()=>showmediapreview(process.env.REACT_APP_IMG_URL + data.body)} style={{position:'absolute', left:0}}></a>
                      </div>
                    )) ||
                    ReactHtmlParser(data.body)}

                  <small className="time" title={data?.ack}>
                    <span className="float-end ms-2">
                      
                    {data?.ack==='sent' && <Done style={{fontSize:16}} />}
                    {data?.ack==='delivered' && <DoneAll style={{fontSize:16}} />}
                    {data?.ack==='read' && <DoneAll style={{fontSize:16, color: '#53bdeb'}} />}
                    {data?.ack==='failed' && <span className="badge bg-danger fw-normal">Failed</span>}
                    </span>
                  <span className="float-end">
                  {moment(data?.createdDate).format("DD-MMM-YY hh:mm a")}
                  </span>
                    
                  </small>
                 
                </p>
              </li>
            );
          }}
        />
      ) : (
        <div
          className="chat-bg"
          style={{
            height: "57vh",
            overflowX: "hidden",
            backgroundImage: `url(require('./../../images/wsbg.png'))`,
            flex: 1,
          }}
        ></div>
      )}
      <div className="send-box">
        <form action="">
          {((!isOlderThan24Hours && !props?.contact?.blocked?.includes(props?.currentTenantnumber?.id)) || props?.currentTenantnumber?.account_type ==='webbot') ? (
            <>
              <a
                className="me-2 text-black"
                onClick={() => setissmiley(!issmiley)}
              >
                <EmojiEmotionsOutlined />
              </a>
              {issmiley && (
                <div
                  style={{
                    position: "absolute",
                    left: 10,
                    bottom: 70,
                    background: "#fff",
                    zIndex: 999,
                  }}
                >
                  <EmojiPicker
                    className="position-absolute"
                    // reactionsDefaultOpen={true}
                    onEmojiClick={(event, emojiObject) => {
                      setInputext(prevInput => prevInput + emojiObject.emoji);
                    }}
                  />
                </div>
              )}

            {props?.currentTenantnumber?.account_type !='webbot' && <a
                className="me-2 text-black"
                onClick={() => setistemplate(!istemplate)}
              >
                <TextsmsOutlined />
              </a>}

              {props?.currentTenantnumber?.catalog_id?.length > 1 && props?.currentTenantnumber?.account_type !='webbot' && (
                <a
                  className="me-2 text-black"
                  onClick={() => {
                    setisproduct(!isproduct);
                    setprodlist([]);
                  }}
                >
                  <FormatListBulleted />
                </a>
              )}

             {props?.currentTenantnumber?.account_type !='webbot' && <a
                aria-controls="simple-menu"
                aria-haspopup="true"
                ref={inputEl}
                className="me-2 text-black"
                onClick={() => setshowmediamenu(!showmediamenu)}
              >
                <AttachFileOutlined />
              </a>}

              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={inputEl.current}
                open={showmediamenu}
                onClose={() => setshowmediamenu(false)}
                getContentAnchorEl={null}
              >
                <MenuItem onClick={() => handleCloseMenu("image")}>
                  Image
                </MenuItem>
                <MenuItem onClick={() => handleCloseMenu("video")}>
                  Video
                </MenuItem>
                <MenuItem onClick={() => handleCloseMenu("document")}>
                  Document
                </MenuItem>
              </Menu>
              <textarea
                type="text"
                className="form-control w-100"
                aria-label="message…"
                placeholder="Write message…"
                value={inputext}
                onChange={e => setInputext(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    props?.currentTenantnumber?.account_type ==='webbot'?sendbotmsg():sendMsg("text")
                  }
                }}
              />
            </>
          ) : (
            <>
              <a
                className="me-2 text-black"
                onClick={() => setistemplate(!istemplate)}
              >
                <TextsmsOutlined />
              </a>
              <div></div>
              <div className="w-100 border p-2 bg-lightgray">
                {isOlderThan24Hours?'The customer has not spoken to you in the last 24 hours. You can only send pre-approved templates.':'The customer has unsubscribed. you can not send messages.'}
                
              </div>
            </>
          )}
          <button
            type="button"
            className="btn btn-success"
            onClick={() => props?.currentTenantnumber?.account_type ==='webbot'?sendbotmsg():sendMsg("text")}
            disabled={!inputext.length}
          >
            <SendSharp />
          </button>
        </form>
      </div>

      <Dialog
        open={istemplate}
        onClose={() => setistemplate(!istemplate)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Pre-approved Templates"}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-lg-4 col-12">
              <ul class="list-group">
                {props.templates.map(item => (
                  <a
                    className={`list-group-item ${
                      currentTemplate.name === item.name ? "bg-lightgray" : ""
                    }`}
                    onClick={() => {
                      // console.log(item)
                      setcurrentTemplate(item);
                    }}
                  >
                    {item.name}
                  </a>
                ))}
              </ul>
            </div>
            <div
              className="col-lg-8 col-12 py-2"
              style={{ background: "#d9fdd2", borderRadius: 5, minHeight: 200 }}
            >
              {currentTemplate?.components?.map(
                item =>
                  (item.type === "HEADER" && (
                    <>
                      {" "}
                      {(item.format === "IMAGE" && (
                        <>
                          {tempheaderimage.length > 2 && (
                            <img
                              src={
                                tempheaderimage ||
                                "https://placehold.co/400x100" ||
                                item.example.header_handle[0]
                              }
                              className="w-100"
                            />
                          )}

                          <input
                            type="text"
                            value={tempheaderimage}
                            class="form-control form-control mt-2"
                            placeholder="Enter Image Url"
                            onChange={e => settempheaderimage(e.target.value)}
                          />

                          <p className="my-2 text-center">Or</p>

                          <input
                            type="file"
                            name="file"
                            label="Choose Image "
                            accept=".png,.jpg,.gif"
                            onChange={handleInputChangeForFileType}
                            id="images"
                            className="w-100 p-0 border-0"
                          />
                        </>
                      )) ||
                        (item.format === "VIDEO" && (
                          <>
                            {tempheaderimage.length > 2 && (
                              <video
                                className="w-100"
                                height="240"
                                src={
                                  tempheaderimage ||
                                  "https://placehold.co/400x100" ||
                                  item.example.header_handle[0]
                                }
                              />
                            )}
                            <input
                              type="text"
                              value={tempheaderimage}
                              class="form-control form-control mt-2"
                              placeholder="Enter video Url"
                              onChange={e => settempheaderimage(e.target.value)}
                            />

                            <p className="my-2 text-center">Or</p>

                            <input
                              type="file"
                              name="file"
                              label="Choose Video (mp4 only)"
                              accept=".mp4"
                              onChange={handleInputChangeForFileType}
                              id="images"
                              className="w-100 p-0 border-0"
                            />
                          </>
                        )) ||
                        (item.format === "DOCUMENT" && (
                          <>
                            {tempheaderimage.length > 2 && (
                              <embed
                                src={
                                  tempheaderimage ||
                                  "https://placehold.co/400x100" ||
                                  item.example.header_handle[0]
                                }
                                className="w-100"
                                height="240"
                              />
                            )}
                            <input
                              type="text"
                              value={tempheaderimage}
                              class="form-control form-control mt-2"
                              placeholder="Enter Document Url"
                              onChange={e => settempheaderimage(e.target.value)}
                            />

                            <p className="my-2 text-center">Or</p>

                            <input
                              type="file"
                              name="file"
                              label="Choose Document (pdf only)"
                              accept=".pdf"
                              onChange={handleInputChangeForFileType}
                              id="images"
                              className="w-100 p-0 border-0"
                            />
                          </>
                        ))}
                      {item.format === "TEXT" && (
                        <>
                          <p className="mt-2">{item.text}</p>
                        </>
                      )}
                    </>
                  )) ||
                  (item.type === "BODY" && (
                    <p className="mt-2">{item.text}</p>
                  )) ||
                  (item.type === "FOOTER" && (
                    <p className="small">{item.text}</p>
                  )) ||
                  (item.type === "BUTTONS" && (
                    <>
                      {item?.buttons?.map(btn => (
                        <>
                          <a className="mb-2" href={btn.url} target="blank">
                            {btn.text}
                          </a>{" "}
                          <br />
                        </>
                      ))}
                    </>
                  )),
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTemplateModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              sendMsg(
                "template",
                currentTemplate?.components?.[0].format?.toLowerCase(),
              )
            }
            color="primary"
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isproduct}
        onClose={() => setistemplate(!isproduct)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Search product"
              onChange={e => props.getproductlist(e.target.value)}
            />
            {/* <span class="input-group-text" id="basic-addon2">@example.com</span> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <ul class="list-group">
                {props.products.length > 0 ? (
                  props.products.map((item, i) => (
                    <a
                      className={`list-group-item ${
                        prodlist.find(x => x.retailer_id === item.retailer_id)
                          ? "bg-lightgray"
                          : ""
                      }`}
                      onClick={() => {
                        updatetempprodarray(item);
                      }}
                    >
                      {item.name} ({item.retailer_id})
                    </a>
                  ))
                ) : (
                  <div class="d-flex justify-content-center my-5">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTemplateModalproduct} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => sendMsg("interactive")}
            color="primary"
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ismediaupload}
        onClose={() => setismediaupload(!ismediaupload)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <div
                {...getRootProps({
                  className:
                    "dropzone p-3 border border-4 text-center djminheight",
                })}
              >
                <input {...getInputProps()} />
                <p className="m-2">
                  Drag 'n' drop file here, or click to select file
                </p>
                {(mediatype === "image" && (
                  <div>Allowed file types: png, jpeg (max 5mb)</div>
                )) ||
                  (mediatype === "video" && (
                    <div>Allowed file types: mp4, 3gp (max 5mb)</div>
                  )) ||
                  (mediatype === "document" && (
                    <div>
                      Allowed file types: txt, docx, csv, pdf, xls, ppt (max
                      5mb)
                    </div>
                  ))}
                {tempheaderimage.length > 2 && (
                  <aside className="mt-2">{thumbs}</aside>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setismediaupload(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => sendMsg("media")} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <PreviewModal
        visible={previewmedia}
        setVisible={setPreviewmedia}
        urls={[mediaurl]}
      />

    </>
  );
};

const mapStateToProps = state => ({
  msgs: state.msg.msgs,
  metamsgs: state.msg.metaMsg,
  templates: state.msg.msgstemplates,
});

const mapActionToProps = {
  fetchmsgs: msgs.Pagination,
  sendMsg: msgs.sendwsmsg,
  sendbotmsg: msgs.sendbotmsg,
  fetchTemplates: msgs.fetchTemplates,
  fetchsocketmsg: msgs.fetchsocketmsg,
  fetchsocketmsg: msgs.fetchsocketmsg,
  update_acks: msgs.update_acks,
};

export default connect(mapStateToProps, mapActionToProps)(Chatbox);
