import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import moment from "moment";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = { 
	id: null,
  mobile: "",
  firstname: "",
  lastname: "",
  email: "",
  birthday: moment().format("YYYY-MM-DD"),
  tenants: [],
  spam: false,
  labels: [],

}

const FormDialogEditCustomer = (props) => {
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(initialFormState);
  const [errors, setErrors ] = useState({})

  const handleClickOpen = () => {
      setErrors({});
      setCustomer({
        id : props.dataCustomer[0],
        mobile: props.dataCustomer[1],
        firstname : props.dataCustomer[2],
        lastname : props.dataCustomer[3],
        email : props.dataCustomer[4],
        birthday : props.dataCustomer[5],
        tenants: props.dataCustomer[6],
        spam: props.dataCustomer[7],
        labels:  props.dataCustomer[8]?props.dataCustomer[8]:[],
      })
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  }

  const handleInputChange = event => {
		const { name, value } = event.target
    setCustomer({ ...customer, [name]: value })
  }
  
  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;
    if (!customer.mobile || customer.mobile.trim() === "") {
      formIsValid = false;
      tempErrors["mobile"] = "Cannot be empty";
    }
    if (!customer.firstname || customer.firstname.trim() === "") {
      formIsValid = false;
      tempErrors["firstname"] = "Cannot be empty";
    }
    if (!customer.lastname || customer.lastname.trim() === "") {
      formIsValid = false;
      tempErrors["lastname"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }


  const handleSubmit = (e) => {
      const onSuccess = () => {
          setOpen(false);
          toast.success('Data succesfully updated');
      }
      e.preventDefault();

      if(validate()){
        props.update(customer.id, customer, onSuccess)
      }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
          <EditIcon />
      </IconButton>
      <Dialog  
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
            <DialogTitle id="form-dialog-title" style={{padding: "30px 30px 0px 30px"}}>Edit Customer</DialogTitle>

            <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          
          <div className="row">
          <div className="col-lg-6 col-12">
              <TextField

                name="mobile"
                label="Mobile"
                value={customer.mobile}
                fullWidth
                onChange={handleInputChange}
                {...(errors.mobile && { error: true, helperText: errors.mobile })}
                style={{ marginBottom: 25 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField
                autoFocus
                name="firstname"
                label="First Name"
                value={customer.firstname}
                fullWidth
                onChange={handleInputChange}
                {...(errors.firstname && { error: true, helperText: errors.firstname })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6 col-12">
              <TextField

                name="lastname"
                label="Last Name"
                value={customer.lastname}
                fullWidth
                onChange={handleInputChange}
                {...(errors.lastname && { error: true, helperText: errors.lastname })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <TextField
                name="email"
                label="Email"
                value={customer.email}
                fullWidth
                onChange={handleInputChange}
                {...(errors.email && { error: true, helperText: errors.email })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
          </div>
        
          <div className="row">
          <div className="col-lg-12 col-12 mb-3">
          <TextField
          type="date"
                name="birthday"
                label="DOB"
                value={moment(customer.birthday).format("YYYY-MM-DD")}
                fullWidth
                onChange={handleInputChange}
                {...(errors.birthday && { error: true, helperText: errors.birthday })}
                required
                style={{ marginBottom: 15 }}
              />
            </div>
            <div className="col-lg-12 col-12 mb-3">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Spam</InputLabel>
                <Select
                  name="spam"
                  label="Spam"
                  value={customer.spam}
                  onChange={handleInputChange}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className="col-lg-12 col-12">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Organizations</InputLabel>
                <Select
                  name="tenants"
                  label="tenants"
                  value={customer.tenants}
                  onChange={handleInputChange}
                  multiple
                >
                  {props.tenants.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div> */}
            <div className="col-lg-12 col-12 mt-4">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Labels</InputLabel>
                <Select
                  name="labels"
                  label="Labels"
                  value={customer.labels}
                  onChange={handleInputChange}
                  multiple
                >
                  {props.labels.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
          </div>
      


        </DialogContent>

            <DialogActions style={{padding: 30}}>
                <Button variant="contained" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit} color="secondary">
                    Save
                </Button>
            </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditCustomer;