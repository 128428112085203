import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/label";
import * as tenants from "../../../actions/tenant";

import FormDialogAddLabel from "../formDialog/FormDialogAddLabel";
import FormDialogEditLabel from "../formDialog/FormDialogEditLabel";
import FormDialogDeleteLabel from "../formDialog/FormDialogDeleteLabel";
import moment from "moment";
const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const LabelTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [rowsSelected, setRowsSelected] = useState([])
    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)

    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (name) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, name)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "candelete",
            label: "candelete",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                  return <span className={value?"badge bg-secondary text-capitalize":"badge bg-danger text-capitalize"}>{value?'Custom':'System'}</span>
                },
                customHeadRender: (value, tableMeta, updateValue) =>  {
                    return <th class="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-103 MUIDataTableHeadCell-fixedHeader-104" scope="col" data-colindex="1" data-tableid="0014152305200584747"><div>Type</div></th>
                  },
              
            }
        },
      
        {
            name: "createdDate",
            label: "Created",
            
            options: {
                filter: true,
                sort: false,
                display:false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddLabel component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    tenants={props.tenants}

                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            {/* <FormDialogEditLabel
                                dataLabel={tableMeta.rowData}
                                update={props.update}
                                tenants={props.tenants}

                            /> */}
                           {tableMeta?.rowData?.[2]!== false && <FormDialogDeleteLabel 
                                dataLabel={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />}
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'standard',
        selectableRows: false,
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,
        onRowsSelect: (rowsSelected, allRows) => {
           // this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
          },
          rowsSelected: rowsSelected,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.labels}
            columns={columns}
            options={options}
            title={"Labels"}
        />
    );
}

const mapStateToProps = state => ({
    labels: state.label.labels,
    meta: state.label.metaLabel,
    tenants: state.tenant.tenants_all,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllTenants: tenants.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(LabelTable));