import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/user";
import * as tenants from "../../../actions/tenant";
import FormDialogAddUser from "../formDialog/FormDialogAddUser";
import FormDialogEditUser from "../formDialog/FormDialogEditUser";
import FormDialogDeleteUser from "../formDialog/FormDialogDeleteUser";
import moment from "moment";
const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const UserTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)
        props.fetchAllTenants()
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (firstName, email) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, firstName, email)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "image",
            label: "Avatar",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <img src={process.env.REACT_APP_IMG_URL + value} height="30" />
                }
            },
            
        },
        {
            name: "firstname",
            label: "First Name",
            options: {
                filter: true,
                sort: false,
              
            },
            
        },
        {
            name: "lastname",
            label: "Last Name",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} 
                            style={{
                                paddingLeft: "31px", 
                                fontWeight:500, 
                                borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                                }}
                        >
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value}
                        </span>
                    );
                }
            },
            
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "displayname",
            label: "Display Name",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span className={value==='agent'?"badge bg-secondary text-capitalize":"badge bg-danger text-capitalize"}>{value}</span>
                }
            }
        },
        {
            name: "password",
            label: "Password",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "tenants",
            label: "Tenants",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        
        {
            name: "createdDate",
            label: "Created",
            
            options: {
                filter: true,
                sort: false,
                display:false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddUser component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    tenants={props.tenants}

                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    console.log('tableMeta',tableMeta)
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            <FormDialogEditUser
                                dataUser={tableMeta.rowData}
                                update={props.update}
                                tenants={props.tenants}

                            />
                          {tableMeta.rowData[8]!=="superadmin" &&  <FormDialogDeleteUser 
                                dataUser={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />}
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'vertical',
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.users}
            columns={columns}
            options={options}
            title={"User/Agents Management"}
        />
    );
}

const mapStateToProps = state => ({
    users: state.user.users,
    meta: state.user.metaUser,
    tenants: state.tenant.tenants_all,
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllTenants: tenants.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(UserTable));