import API from "../utils/api";

export const ACTION_TYPES = {
    ROOM_CREATE: 'ROOM_CREATE',
    ROOM_UPDATE: 'ROOM_UPDATE',
    ROOM_DELETE: 'ROOM_DELETE',
    ROOM_FETCH: 'ROOM_FETCH',
    ROOM_FETCH_ALL: 'ROOM_FETCH_ALL',
    ROOM_PAGINATION: 'ROOM_PAGINATION'
}

export const fetchAll = (input) => dispatch => {
    API.room().fetchAll(input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.ROOM_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, business_mobile = "", customer ="") => dispatch => {
    //console.log('rooms',business_mobile, customer)
    API.room().fetchPagination(page, Math.abs(limit), business_mobile, customer)
        .then(res =>{
          

            dispatch({
                type: ACTION_TYPES.ROOM_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.room().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ROOM_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.room().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ROOM_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (input, onSuccess) => dispatch => {

    API.room().update(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ROOM_UPDATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.room().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.ROOM_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const findidpdate = (id, input, onSuccess) => dispatch => {

    API.room().findidpdate(id, input)
        .then(res =>{
            console.log('wee', res.data)
            dispatch({
                type: ACTION_TYPES.ROOM_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}