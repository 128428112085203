import { combineReducers } from "redux";
import { user } from "./user";
import { tenant } from "./tenant";
import { customer } from "./customer";
import { tenantnumber } from "./tenantnumber";
import { msg } from "./msg";
import { room } from "./room";
import { campaign } from "./campaign";
import { label } from "./label"


export const reducers = combineReducers({
     user, tenant , customer, tenantnumber, msg, room, campaign, label
})

