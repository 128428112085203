import { ACTION_TYPES } from "../actions/tenant";

const initialState = {
    tenants: [],
    metaTenant: {},
    tenants_all: []
}

export const tenant = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.TENANT_FETCH_ALL:
            return {
                ...state,
                tenants_all: [...action.payload]
            }
        case ACTION_TYPES.TENANT_CREATE:
            return {
                ...state,
                tenants: [...state.tenants, action.payload]
            }
        case ACTION_TYPES.TENANT_UPDATE:
            return {
                ...state,
                tenants: state.tenants.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.TENANT_DELETE:
            return {
                ...state,
                tenants: state.tenants.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.TENANT_PAGINATION:

            return {
                ...state,
                tenants: [...action.payload.tenants],
                metaTenant: action.payload.meta
            }

        case ACTION_TYPES.TENANT_CURRENT:
            return {
                ...state,
                currentTenant: action.payload
            }
        default:
            return state;
    }
}