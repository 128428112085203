import React from "react";
import logo from "../../images/logo.png"

const PrivacyPolicy = () => {
  return (
    <>
      <div class="container" itemprop="mainEntityOfPage">
        <div className="text-center pt-5">
        <h1><img src={logo} alt="logo" height={90} /> Pulse CRM</h1>
        </div>
       
      

        <h2 class="wp-block-heading">Who we are</h2>

        <p>Our website address is: https://www.whatsapp.alzaabigroup.com.</p>
        <p>
          <strong>INTRODUCTION</strong>
        </p>

        
        <p>
          This Privacy Policy (the “Policy”) describes how the Al Zaabi Group
          Trading collects, uses and shares the information you provide to us
          and the information we collect in the course of operating our business
          and our websites (regardless of where you visit it from) and informs
          you about your privacy rights and how the law protects you. We
          appreciate that there is a lot of information here but we want you to
          be fully informed about your rights and how Al Zaabi Group Trading
          uses your data. This Policy is provided in a layered format so you can
          click through to the specific areas set out below. Alternatively you
          can download a pdf version of the policy here.
        </p>
        
        <p>
          <strong>IMPORTANT INFORMATION AND WHO WE ARE</strong>
        </p>
        
        <p>
          <strong>PURPOSE OF THIS POLICY</strong>
        </p>
        
        <p>
          It is important that you read this Policy together with any other
          privacy notice or fair processing notice we may provide on specific
          occasions when we are collecting or processing personal data about you
          so that you are fully aware of how and why we are using your data.
        </p>
        
        <p>
          <strong>DATA CONTROLLER</strong>
        </p>
        
        <p>
          Al Zaabi Group Trading is made up of different legal entities, details
          of which can be found here. This Policy is issued on behalf of the Al
          Zaabi Group Trading, so when we mention, “we”, “us” or “our” in this
          Policy, we are referring to the relevant company in the Al Zaabi Group
          Trading responsible for processing your data.
        </p>
        
        <p>
          We will let you know which entity will be the controller for your data
          when you purchase a product or service from us. Specifically, your
          data will be controlled by the Al Zaabi Group Trading entity that you
          have approached to provide services to you in the region.
        </p>
        
        <p>
          You have the right to lodge a complaint with a supervisory authority,
          in particular in the member state of your habitual residence, place of
          work or of an alleged infringement of applicable data protection laws,
          for example the GDPR and applicable data protection laws.
        </p>
        
        <p>
          CHANGES TO THE POLICY MADE BY US AND YOUR DUTY TO INFORM US OF CHANGES
          RELATED TO YOU
        </p>
        
        <p>
          It is likely that we will need to update this Policy from time to
          time. We will notify you of any significant changes. It is important
          that the personal data we hold about you is accurate and current.
          Please keep us informed if your personal data changes during your
          relationship with us.
        </p>
        
        <p>THIRD-PARTY LINKS</p>
        
        <p>
          Our website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy notice of every website you visit.
        </p>
        
        
        <p>
          <strong>
            LEGAL BASES WE RELY ON TO COLLECT / PROCESS YOUR PERSONAL DATA
          </strong>
        </p>
        
        <p>
          The law on data protection allows the collection and processing of
          personal data for a number of different reasons, such as:
        </p>
        <p>
          Consent: In specific situations, we can collect and process your data
          with your consent. For example, when you tick a box on our website to
          receive e-mail news updates.
        </p>
        <p>
          Performance of contract with you: means processing your data where it
          is necessary for the performance of a contract to which you are a
          party or to take steps at your request before entering into such a
          contract. For example, to register you as a new customer.
        </p>
        <p>
          Comply with a legal or regulatory obligation: means processing your
          personal data where it is necessary for compliance with a legal or
          regulatory obligation that we are subject to. For example, we can pass
          on personal data to prevent and respond to actual or potential fraud
          or other illegal activities.
        </p>
        <p>
          Legitimate Interest: means our legitimate interest in conducting and
          managing our business to enable us to give you the best service or
          product and the most secure experience. We make sure we consider and
          balance any potential impact on you (both positive and negative) and
          your rights before we process your personal data for our legitimate
          interests. For example, if we engage in marketing and business
          development activity in relation to our financial services which may
          include updates, marketing communications and other information that
          may be of interest to you.
        </p>
        <p>
          <strong>THE DATA WE COLLECT ABOUT YOU</strong>
        </p>
        <p>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data). We
          may collect, use, store and transfer different kinds of personal data
          about you which we have grouped together as follows:
        </p>
        
        <p>
          Identity Data may include first name, maiden name, last name, username
          or similar identifier, marital status, title, date of birth and
          gender, nationality, passport, national insurance number or other tax
          payer data, your job title or position, name of your employer and, in
          the case of any recruitment application, education and qualification
          details, your CV, photograph and any other details set out in your
          application.
        </p>
        
        <p>
          Contact Data includes billing address, business address, delivery
          address, email address and telephone numbers.
        </p>
        <p>
          Financial Data includes income, net worth, bank account and payment
          card details.
        </p>
        <p>
          Transaction Data includes details about payments to and from you and
          other details of products and services you have purchased from us.
        </p>
        <p>
          Technical Data includes internet protocol (IP) address, your login
          data, browser type and version, time zone setting and location,
          browser plug-in types and versions, screen resolution, operating
          system and platform and other technology on the devices and types of
          devices you use to access this website.
        </p>
        <p>
          Profile Data includes your username and password, purchases or orders
          made by you, your interests, preferences, feedback and survey
          responses.
        </p>
        <p>
          Usage Data includes information about how you use our website,
          products and services.
        </p>
        <p>
          Marketing and Communications Data includes your preferences in
          receiving marketing from us and our third parties and your
          communication preferences.
        </p>
        <p>
          We also collect, use and share Aggregated Data such as statistical or
          demographic data for any purpose. Aggregated Data may be derived from
          your personal data but is not considered personal data in law as this
          data does not directly or indirectly reveal your identity. For
          example, we may aggregate your Usage Data to calculate the percentage
          of users accessing a specific website feature. However, if we combine
          or connect Aggregated Data with your personal data so that it can
          directly or indirectly identify you, we treat the combined data as
          personal data which will be used in accordance with this Policy. We do
          not collect any Special Categories of Personal Data about you (this
          includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health and
          genetic and biometric data). Nor do we routinely collect any
          information about criminal convictions and offences.
        </p>
        
        
        <p>
          <strong>IF YOU DO NOT&nbsp; PROVIDE SPECIFIC PERSONAL DATA</strong>
        </p>
        
        <p>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you and you do not provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with
          products or services). In this case, we may have to cancel a product
          or service you have with us but we will notify you if this is the case
          at the time.
        </p>
        
        
        <p>
          <strong>HOW IS YOUR PERSONAL DATA COLLECTED?</strong>
        </p>
        
        <p>
          In this context, we use different methods to collect data from and
          about you including through:
        </p>
        <p>
          Direct interactions. You may give us your Identity, Contact and
          Financial Data by filling in forms or by corresponding with us by
          post, phone, email or otherwise. This includes personal data you
          provide when you:
        </p>
        <p>apply for our products or services;</p>
        <p>create an account on our website;</p>
        <p>subscribe to our service or publications;</p>
        <p>request marketing to be sent to you;</p>
        <p>enter a competition, promotion or survey; or</p>
        <p>give us some feedback.</p>
        <p>
          Automated technologies or interactions. As you interact with our
          website, we may automatically collect Technical Data about your
          equipment, browsing actions and patterns. We collect this personal
          data by using cookies, server logs and other similar technologies. We
          may also receive Technical Data about you if you visit other websites
          employing our cookies. Third parties or publicly available sources. We
          may also receive personal data about you from various third parties
          and public sources, examples of which are listed below.
        </p>
        
        
        <p>
          <strong>HOW WE USE YOUR PERSONAL DATA</strong>
        </p>
        
        <p>
          We will only use your personal data when the law allows us to. We have
          set out below, in a table format, a description of the ways we plan to
          use your personal data in the ordinary course of business, and which
          of the legal bases we rely on to do so. We have also identified what
          our Legitimate Interests are where appropriate. Note that we may
          process your personal data for more than one lawful basis depending on
          the specific purpose for which we are using your data.
        </p>
        
        <p>
          We may collate, process and share any statistics based on an
          aggregation of information held by us provided that any individual is
          not identified from the resulting analysis and the collation,
          processing and dissemination of such information is permitted by law.
          Generally, we do not rely on consent as a legal basis for processing
          your personal data other than in relation to third party direct
          marketing communications to you via email or text message.&nbsp;
        </p>
        
        
        <p>
          <strong>MARKETING AND DATA CONTROL MECHANISMS</strong>
        </p>
        
        <p>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising. We may use your
          Identity, Contact, Technical, Usage and Profile Data to form a view on
          what we think you may want or need, or what may be of interest to you.
          This is how we decide which products, services and offers may be
          relevant for you (we call this marketing).&nbsp; You will receive
          marketing communications from us if you have requested information
          from us or purchased products or services from us or if you provided
          us with your details when you entered a competition or registered for
          a promotion and, in each case, you have not opted out of receiving
          that marketing. We have established the following personal data
          control mechanisms:
        </p>
        
        <p>
          <strong>OPTING OUT</strong>
        </p>
        
        <p>
          You can ask us to stop sending you marketing messages at any time by
          i) logging into the website or client portal and checking or
          unchecking relevant boxes to adjust your marketing preferences; or ii)
          by following the opt-out links on any marketing message sent to you.
          Where you opt out of receiving these marketing messages, this will not
          apply to personal data provided to us as a result of a product/service
          experience or other transactions.
        </p>
        
        <p>
          <strong>THIRD-PARTY MARKETING</strong>
        </p>
        
        <p>
          We will get your express opt-in consent before we share your personal
          data with any company outside the Al Zaabi Group Trading for marketing
          purposes.
        </p>
        
        <p>
          <strong>COOKIES</strong>
        </p>
        
        <p>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly.
        </p>
        
        <p>
          <strong>CHANGE OF PURPOSE</strong>
        </p>
        
        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us using the DPO details set out above at the beginning of
          this Policy. If we need to use your personal data for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so.
        </p>
        
        
        <p>
          <strong>DISCLOSURES OF YOUR PERSONAL DATA</strong>
        </p>
        
        <p>
          We may have to share your personal data with the parties set out below
          for the purposes set out in the table in paragraph 5 above. Internal
          Third Parties , such as, other companies in the Al Zaabi Group Trading
          acting as joint controllers or processors and who are based in the
          United Arab Emirates, Singapore, Hong Kong and the United Kingdom and
          provide IT and system administration services and undertake management
          reporting. External Third Parties, such as:
        </p>
        <p>
          Service providers acting as processors based both inside and outside
          the EEA who provide IT and system administration services.
        </p>
        <p>
          Service providers based both inside and outside the EEA who, acting as
          processors, provide us with cloud-based customer relationship
          management services in order to help us improve our customer services
          and support. We currently use Salesforce to provide these services.
        </p>
        <p>
          Professional advisers acting as processors or joint controllers
          including lawyers, bankers, auditors and insurers based both inside
          and outside the EEA who provide consultancy, banking, legal, insurance
          and accounting services.
        </p>
        <p>
          HM Revenue &amp; Customs, regulators and other authorities acting as
          processors or joint controllers based in the United Arab Emirates who
          require reporting of processing activities in certain circumstances.
        </p>
        <p>
          Specific third parties e.g. companies who support our website, direct
          marketing companies, Google, Facebook and data analytics companies
          including Adobe Analytics who measure our website usage and trends in
          order to help us to improve our website’s overall effectiveness. Your
          Technical Data may also be shared with Criteo who, acting as
          controllers, help us to gather information about your web browsing
          actions and patterns in order to enable us to provide you with
          personalised advertisements. This type of data may also be collected
          for cross-device linking purposes. Third parties to whom we may choose
          to sell, transfer, or merge parts of our business or our assets.
          Alternatively, we may seek to acquire other businesses or merge with
          them. If a change happens to our business, then the new owners may use
          your personal data in the same way as set out in this privacy notice.
        </p>
        
        
        <p>
          <strong>FRAUD PREVENTION AGENCIES (FPAS)</strong>
        </p>
        
        <p>
          Before we provide products or services to you, we also undertake
          checks for the purposes of preventing fraud and money laundering, and
          to verify your identity. These checks require us to process personal
          data about you. The personal data you have provided, we have collected
          from you, or we have received from third parties will be used to
          prevent fraud and money laundering, and to verify your identity.
          Details of the personal information that will be processed include,
          for example: name, address, date of birth, contact details, financial
          information, and device identifiers including IP address. We and fraud
          prevention agencies may also enable law enforcement agencies to access
          and use your personal data to detect, investigate and prevent crime.
          We process your personal data on the basis that we have a Legitimate
          Interest in preventing fraud and money laundering, and to verify your
          identity, in order to protect our business and to comply with laws
          that apply to us. Such processing is also a contractual requirement of
          the products and services you have requested. Fraud prevention
          agencies can hold your personal data for different periods of time,
          and if you are considered to pose a fraud or money laundering risk,
          your data can be held for up to six years.
        </p>
        
        
        <p>
          <strong>CONSEQUENCES OF PROCESSING</strong>
        </p>
        
        <p>
          A record of any fraud or money laundering risk will be retained by
          fraud prevention agencies, and may result in others refusing to
          provide products or services to you.
        </p>
        
        <p>
          DATA TRANSFER Whenever fraud prevention agencies transfer your
          personal data outside of the European Economic Area (“EEA”), they
          impose contractual obligations on the recipients of that data to
          protect your personal data to the standard required by the European
          Economic Area. They may also require the recipient to subscribe to
          “international frameworks” intended to enable secure data sharing. We
          require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>
        
        
        <p>
          <strong>INTERNATIONAL TRANSFERS</strong>
        </p>
        <p>
          We share your personal data within the Al Zaabi Group Trading Group.
          This will involve transferring your data outside the EEA to other Al
          Zaabi Group Trading Group members or approved associates/partners as
          well as processors and potentially controllers who are engaged on our
          behalf. For example we have Al Zaabi Group Trading Group companies
          located in the UAE, China and Hong Kong. To the extent we transfer
          your information outside the EEA, and where these jurisdictions do not
          have a finding of adequacy by the applicable authorities or
          regulators, we will enter into agreements with these entities to
          ensure that appropriate safeguards are in place such as standard
          contractual clauses adopted by the European Commission. Please contact
          us using the DPO details set out above at the beginning of this Policy
          if you want further information on the specific mechanism used by us
          when transferring your personal data out of the EEA.
        </p>
        
        
        <p>
          <strong>DATA SECURITY</strong>
        </p>
        <p>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality. We have put in place procedures to deal
          with any suspected personal data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do
          so.
        </p>
        
        
        <p>
          <strong>DATA RETENTION</strong>
        </p>
        
        <p>HOW LONG WILL WE USE YOUR PERSONAL DATA FOR?</p>
        
        <p>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements. To
          determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements. By law we have to keep basic
          information about our customers (including Contact, Identity,
          Financial and Transaction Data) for a set minimum period after they
          cease being customers. In some circumstances you can ask us to delete
          your data: see Your Legal Rights below for further information. In
          some circumstances we may anonymise your personal data (so that it can
          no longer be associated with you) for research or statistical purposes
          in which case we may use this information indefinitely without further
          notice to you.
        </p>
        
        
        <p>
          <strong>YOUR LEGAL RIGHTS</strong>
        </p>
        <p>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data:
        </p>
        <p>Request access to the personal data we hold about you.</p>
        <p>
          Request correction of your personal data when incorrect, out of date
          or incomplete.
        </p>
        <p>Request erasure of your personal data.</p>
        <p>Object to processing of your personal data.</p>
        <p>Request restriction of processing your personal data.</p>
        <p>Request transfer of your personal data.</p>
        <p>Right to withdraw consent.</p>
        <p>
          If you wish to exercise any of the rights set out above, please
          contact us using the DPO details set out above at the beginning of
          this Policy.
        </p>
        
        
        <p>NO FEE USUALLY REQUIRED</p>
        
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly manifestly unfounded, repetitive or
          excessive.
        </p>
        
        
        <p>WHAT WE MAY NEED FROM YOU</p>
        
        <p>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </p>
        
        
        <p>TIME LIMIT TO RESPOND</p>
        
        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>
        
        <div class="clearboth"></div>

        <div class="clearboth"></div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
