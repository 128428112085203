import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/tenant";
import FormDialogAddTenant from "../formDialog/FormDialogAddTenant";
import FormDialogEditTenant from "../formDialog/FormDialogEditTenant";
import FormDialogDeleteTenant from "../formDialog/FormDialogDeleteTenant";
import moment from "moment";
const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const TenantTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (firstName, email) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, firstName, email)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "image",
            label: "Logo",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <img src={process.env.REACT_APP_IMG_URL + value} height="20" />
                }
            }
        },
        {
            name: "business_id",
            label: "Business ID",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,

            },
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "website",
            label: "Website",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "label",
            label: "Label",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "createdDate",
            label: "Created",

            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                <FormDialogAddTenant component={Paper}
                                    create={props.create}
                                    refresh={refresh}
                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <FormDialogEditTenant
                                dataTenant={tableMeta.rowData}
                                update={props.update}
                            />
                            <FormDialogDeleteTenant
                                dataTenant={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'vertical',
        selectableRows: false,
        rowsPerPageOptions: [15, 30, 45],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,

        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <MUIDataTable className={classes.paperTable}
            data={props.tenants}
            columns={columns}
            options={options}
            title={"Tenants"}

        />
    );
}

const mapStateToProps = state => ({
    tenants: state.tenant.tenants,
    meta: state.tenant.metaTenant
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(TenantTable));