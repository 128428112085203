import { ACTION_TYPES } from "../actions/label";

const initialState = {
    labels: [],
    metaCustomer: {}
}

export const label = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LABEL_FETCH_ALL:
            return {
                ...state,
                labels: [...action.payload]
            }
        case ACTION_TYPES.LABEL_CREATE:
            return {
                ...state,
                labels: [...state.labels, action.payload]
            }
        case ACTION_TYPES.LABEL_UPDATE:
            return {
                ...state,
                labels: state.labels.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.LABEL_DELETE:
            return {
                ...state,
                labels:state.labels.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.LABEL_PAGINATION:
         
            return {
                ...state,
                labels: [...action.payload.labels],
                metaLabel: action.payload.meta
            }
        default:
            return state;
    }
}