import React, { useState, useEffect, useContext } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";

import {

  Home as HomeIcon,
  LibraryBooks as LibraryIcon,
  ArrowBack as ArrowBackIcon,
  Person as AccountIcon,
  GroupOutlined as GroupOutlinedIcon,
  CategoryOutlined as CategoryOutlinedIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  BookmarksOutlined as BookmarksOutlinedIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  MenuOpenOutlined as MenuOpenOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  ContactsOutlined as ContactOutlinedIcon,
  Business as Business,
  Dashboard as Dashboard,
  PhoneIphone as PhoneIphone,
  AllInbox as AllInbox,
  ChatBubble as ChatBubble,
  Event as Event,
  Label as Label
  
} from "@material-ui/icons";

import PublicIcon from '@material-ui/icons/Public';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { AuthContext } from "../../context/AuthContext";
// styles
import useStyles from "./styles";
import "./scrollbar.css";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";



function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()

  const structure1 = [
     { id: 0, label: "Dashboard", link: "/admin/dashboard", icon: <Dashboard /> },
    { id: 1, label: "Business Accounts", link: "/admin/tenant", icon: <Business /> },
    { id: 2, label: "Business Numbers", link: "/admin/tenant-numbers", icon: <PhoneIphone /> },
    { id: 3, label: "User Management", link: "/admin/user", icon: <GroupOutlinedIcon /> },
    { id: 4, label: "Contacts", link: "/admin/customer", icon: <ContactOutlinedIcon /> },
    { id: 5, label: "Contact Labels", link: "/admin/label", icon: <Label /> },
    { id: 6, label: "Inbox", link: "/admin/inbox", icon: <ChatBubble /> },
    { id: 7, label: "Campaigns", link: "/admin/campaign", icon: <Event /> },

  ];
  const structure2 = [
    { id: 0, label: "Dashboard", link: "/admin/dashboard", icon: <Dashboard /> },
    { id: 1, label: "Inbox", link: "/admin/inbox", icon: <ChatBubble /> },
    { id: 2, label: "Campaigns", link: "/admin/campaign", icon: <Event /> },
    { id: 3, label: "Contacts", link: "/admin/customer", icon: <ContactOutlinedIcon /> },
    { id: 4, label: "Contact Labels", link: "/admin/label", icon: <Label /> },
   
  ];

  const structure3 = [
    { id: 1, label: "Inbox", link: "/admin/inbox", icon: <ChatBubble /> },

  ];
  let structure 
  switch (authUser.role) {
    case 'agent':
      structure = structure3
      break;
    case 'admin':
      structure = structure2
      break;
    case 'superadmin':
      structure = structure1
      break;

  }
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      style={{display:authUser.role==='agent'?'none':''}}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <div>
        <List className={classes.sidebarList}>
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      </div>
      
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
