import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/customer";
import * as tenants from "../../../actions/tenant";
import * as labels from "../../../actions/label";


import moment from "moment";
const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const CustomerTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [rowsSelectedall, setRowsSelectedall] = useState({})
    const [slectedindexes, setSelctedindexes] = useState({})
    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)
        props.fetchAllTenants()

    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (labels) => {
        console.log(labels)
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '', '', labels)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "firstname",
            label: "First Name",
            options: {
                filter: false,
                sort: false,
            },

        },
        {
            name: "lastname",
            label: "Last Name",
            options: {
                filter: false,
                sort: false,

            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },

        {
            name: "birthday",
            label: "DOB",
            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('DD-MM-YYYY');
                }
            }
        },
        {
            name: "tenants",
            label: "Tenants",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "spam",
            label: "Spam",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "labels",
            label: "Labels",
            options: {
                filter: true,
                sort: false,
                display: true,
                filterType: 'multiselect',
                filterArrayFullMatch: true,
                filterOptions: { fullWidth: true },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return  value?.map((e) => <span className="badge bg-secondary text-capitalize me-1 fw-light">{e}</span>)
                }
            }
        },
        {
            name: "source",
            label: "Source",
            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span className={value === 'in' ? "badge bg-secondary text-capitalize" : "badge bg-danger text-capitalize"}>{value}</span>
                }
            }
        },
        {
            name: "createdDate",
            label: "Created",

            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                        </div>
                    );
                }
            }
        }
    ];

    const findIndicesById = () => {
        return rowsSelectedall?.[page]?.map(id => {
            return props?.customers?.findIndex(obj => obj.id === id);
        });
    }

    useEffect(() => {
        setSelctedindexes((prevState) => ({
            ...prevState,
            [page]: findIndicesById()
        }));
        props.getcustomersslection(rowsSelectedall)
    }, [rowsSelectedall])


    const options =

    {

        responsive: 'vertical',
        selectableRowsHeader: true,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [20],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,
        elevation: 0,
        selectableRows: "multiple",
        filter: false,
        onRowsSelect: (rowsSelected, allRows) => {
            console.log(rowsSelected, allRows, allRows.map(row => props.customers[row.dataIndex].id));

            setRowsSelectedall((prevState) => ({
                ...prevState,
                [page]: allRows.map(row => props.customers[row.dataIndex].id)
            }));

        },
        rowsSelected: slectedindexes[page],

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[8])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        }
    };

    return (
        <MUIDataTable className={classes.paperTable}
            data={props.customers}
            columns={columns}
            options={options}
            title={"Select Customers"}

        />
    );
}

const mapStateToProps = state => ({
    customers: state.customer.customers,
    meta: state.customer.metaCustomer,
    tenants: state.tenant.tenants_all,
    labels: state.label.labels,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllTenants: tenants.fetchAll,
    fetchAllLabels: labels.fetchAll,

}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(CustomerTable));