import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/PollRounded";
import { toast } from "react-toastify";
import Grow from "@material-ui/core/Grow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const FormDialogEditCampaign = props => {
  console.log("campaigns-->", props);
  const [open, setOpen] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [loading, setLoading] = useState(true);
  const [lastrefreshedtime, setlastrefreshedtime] = useState(new Date());

  useEffect(() => {
    const onSuccess = data => {
      setLoading(false);
      setlastrefreshedtime(data);
    };
    // props.getAnalytics(onSuccess);
  }, []);

  useEffect(() => {
    console.log("analytics----->>", props.analytics);

    setAnalytics(props.analytics);
  }, [props.analytics]);

  const handleRefresh = () => {
    setLoading(true);

    const onSuccess = data => {
      setLoading(false);
      setlastrefreshedtime(data);
    };
    props.getAnalytics(onSuccess, { campaign_id: props.dataCampaign[0] });
  };

  const handleClickOpen = () => {
    const onSuccess = data => {
      console.log(data);
      setLoading(false);
      setlastrefreshedtime(data);
    };
    props.getAnalytics(onSuccess, { campaign_id: props.dataCampaign[0] });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton label="Analytics" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "30px 30px 0px 30px" }}
        >
          Campaign Analytics for {props.dataCampaign[1]}
          <button
            className="btn btn-secondary btn-sm p-0 px-2 float-end mb-3"
            onClick={handleRefresh}
            disabled={loading}
          >
            {loading && (
              <span
                class="spinner-border spinner-border-sm me-1"
                style={{ height: 12, width: 12 }}
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {loading ? "Refreshing..." : "Refresh"}
          </button>
        </DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
        {parseInt(analytics.total)>0 ?  <div className="row">
            
            <div className="col-lg-4 col-12">
              <div class="card">
                <div class="card-body">
                  <table class="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td>Total Customers</td>
                        <td className="h4">{analytics.total}</td>
                      </tr>
                      <tr>
                        <td>Sent (Delivery pending)</td>
                        <td className="h4">
                          {analytics.sent}
                        </td>
                      </tr>
                      <tr>
                        <td>Delivered</td>
                        <td className="h4">{analytics.delivered}</td>
                      </tr>
                      <tr>
                        <td>Read</td>
                        <td className="h4">{analytics.read}</td>
                      </tr>
                      <tr>
                        <td>Failed</td>
                        <td className="h4">{analytics.failed}</td>
                      </tr>
                    </tbody>
                  </table>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-12">
              <div class="card">
                <div class="card-body">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Mobile</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analytics?.msg_data?.map(item => (
                        <tr key={item.wa_id}>
                          <td>{item.wa_id}</td>
                          <td>
                            {item?.ack === "failed" ? (
                              <span className="badge bg-danger fw-normal">
                                {item.ack}
                              </span>
                            ) : (
                              <span className="badge bg-success fw-normal">
                                {item.ack}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>{" "}
                </div>
              </div>
            </div>
          </div>:
          <div className="text-center">
          <span
                class="spinner-border spinner-border-sm me-1"
                style={{ height: 12, width: 12 }}
                role="status"
                aria-hidden="true"
              ></span> Loading
          </div>
          
          }
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialogEditCampaign;
