import { ACTION_TYPES } from "../actions/tenantnumber";

const initialState = {
    tenantnumbers: [],
    metaTenantnumber: {},
    currentTenantnumber: {}
}

export const tenantnumber = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.TENANTNUMBER_FETCH_ALL:
            return {
                ...state,
                tenantnumbers: [...action.payload]
            }
        case ACTION_TYPES.TENANTNUMBER_CREATE:
            return {
                ...state,
                tenantnumbers: [...state.tenantnumbers, action.payload]
            }
        case ACTION_TYPES.TENANTNUMBER_UPDATE:
            return {
                ...state,
                tenantnumbers: state.tenantnumbers.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.TENANTNUMBER_DELETE:
            return {
                ...state,
                tenantnumbers: state.tenantnumbers.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.TENANTNUMBER_PAGINATION:

            return {
                ...state,
                tenantnumbers: [...action.payload.tenantnumbers],
                metaTenantnumber: action.payload.meta
            }
        case ACTION_TYPES.TENANTNUMBER_CURRENT:
            return {
                ...state,
                currentTenantnumber: action.payload
            }
        default:
            return state;
    }
}