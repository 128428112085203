import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/customer";
import * as tenants from "../../../actions/tenant";
import * as labels from "../../../actions/label";
import FormDialogAddCustomer from "../formDialog/FormDialogAddCustomer";
import FormDialogEditCustomer from "../formDialog/FormDialogEditCustomer";
import FormDialogDeleteCustomer from "../formDialog/FormDialogDeleteCustomer";
import moment from "moment";
import API from "../../../utils/api";
import Papa from "papaparse";
import { toast } from 'react-toastify';

const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const CustomerTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [rowsSelected, setRowsSelected] = useState([])
    const [searchText, setsearchText] = useState('')
    const [selectedlabels, setselectedlabels] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)
        props.fetchAllTenants()
        props.fetchAllLabels()
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, searchText)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        setsearchText(searchText)
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (firstName, email) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, firstName, email)
    };

    const refresh = async () => {
        
       setPage(0);
        props.fetchPagination(0, rowsPerPage)
    }
    const exporttocsv = () => {
        if(!selectedlabels.length){
            toast.error('Select at least one label.');
            return
        }

        setLoading(true)
        API.customer().exportcsv({labels: selectedlabels})
        .then(res =>{
            setselectedlabels([])
         console.log(res.data)
            const csv = Papa.unparse(res.data)

            var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            var csvURL =  null;
            if (navigator.msSaveBlob)
            {
                csvURL = navigator.msSaveBlob(csvData, selectedlabels+'pulse_crm_export.csv');
            }
            else
            {
                csvURL = window.URL.createObjectURL(csvData);
            }
        
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', selectedlabels+'_pulse_crm_export.csv');
            tempLink.click();
            setLoading(false)
      
        })
        .catch(err => setLoading(false))
    }
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "firstname",
            label: "First Name",
            options: {
                filter: true,
                sort: false,
            },

        },
        {
            name: "lastname",
            label: "Last Name",
            options: {
                filter: true,
                sort: false,
                display: false
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
                display: false,
            }
        },

        {
            name: "birthday",
            label: "DOB",
            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('DD-MM-YYYY');
                }
            }
        },
        {
            name: "tenants",
            label: "Tenants",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "spam",
            label: "Spam",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        
        {
            name: "labels",
            label: "Labels",
            options: {
                filter: true,
                sort: false,
                display: true,
                filterType: 'multiselect',
                filterArrayFullMatch: true,
                filterOptions: { fullWidth: true },
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return  value?.map((e) => <span className="badge bg-success me-1 fw-light">{e}</span>)
                // },
                toStringOverride: (rawDataValue) => {
                    rawDataValue.map(user => user).join(', ') // or whatever else you need that flattens your data
               }
            }
        },
        {
            name: "source",
            label: "Source",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span className={value === 'incoming' ? "badge bg-secondary text-capitalize" : "badge bg-danger text-capitalize"}>{value}</span>
                }
            }
        },
        {
            name: "createdDate",
            label: "Created",

            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('lll');
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{ paddingRight: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                <FormDialogAddCustomer component={Paper}
                                    create={props.create}
                                    refresh={refresh}
                                    tenants={props.tenants}
                                    labels={props.labels}
                                    fetchBulkContacts={props.fetchBulkContacts}

                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <FormDialogEditCustomer
                                dataCustomer={tableMeta.rowData}
                                update={props.update}
                                tenants={props.tenants}
                                labels={props.labels}

                            />
                            <FormDialogDeleteCustomer
                                dataCustomer={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'vertical',
        selectableRows: false,
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        rowsPerPageOptions: [15, 30, 50, 500],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        downloadOptions:{filename: 'contacts.csv', separator: ',', filterOptions:{
            useDisplayedColumnsOnly:  true,
            useDisplayedRowsOnly: true
        }},
        rowsPerPage: rowsPerPage,
        count: props.meta.totalDocs || 0,
        page: page,
        onRowsSelect: (rowsSelected, allRows) => {
            //console.log(rowsSelected, allRows);
            // this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        },
        rowsSelected: rowsSelected,

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    handleChangePage(tableState.page)
                    break;

                case 'changeRowsPerPage':
                    handleChangeRowsPerPage(tableState.rowsPerPage)
                    break;

                case 'search':
                    handleSearch(tableState.searchText)
                    break;

                case 'filterChange':
                    handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                    break;

                case 'resetFilters':
                    handleSearch("")
                    break;

                default:
                    break;
            }
        },
    };

    return (
        <>
        <div className="row my-3 mx-2">
             <div className="col"></div>
        <div className="col-6 mt-1">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Select labels to export</InputLabel>
                <Select
                  name="labels"
                  label="Labels"
                  value={selectedlabels}
                  onChange={e=>{setselectedlabels(e.target.value)

                    handleSearch(e.target.value)
                  }}
                  
                >
                  {props.labels.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
            <div className="col-2">
            {loading?<button class="btn btn-success mt-3 w-100" type="button" disabled>
  <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
   Preparing...
</button>:<a className="btn btn-success w-100 mt-3" onClick={exporttocsv}>Export</a>}
            

            </div>
        </div>
        <MUIDataTable className={classes.paperTable}
            data={props.customers}
            columns={columns}
            options={options}
            title={"Customers"}

        />
        </>
    );
}

const mapStateToProps = state => ({
    customers: state.customer.customers,
    meta: state.customer.metaCustomer,
    tenants: state.tenant.tenants_all,
    labels: state.label.labels,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllTenants: tenants.fetchAll,
    fetchAllLabels: labels.fetchAll,
    fetchBulkContacts: actions.bulkupload

}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(CustomerTable));